import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
function Safe() {
  const [services, sestervices] = useState([]);

  // getdata
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppytilesection"
      );
      console.log(response.data);
      sestervices(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <section className="service-two mb--120">
        <div className="service-two__shape"></div>
        <div className="container">
          <div className="row row-gutter-y-30">
            {services.map((service, index) => (
              <div className="col-lg-4 col-md-12" key={index}>
                <div className="service-card-two">
                  <div className="service-card-two__shape"></div>
                  <div className="service-card-two__icon">
                    <i className={service.titeicon}></i>
                  </div>
                  <div className="service-card-two__content">
                    <h3 className="service-card-two__title">
                      {/* <Link to={service.tilelink}>{service.tiletitle}</Link> */}
                      {service.tiletitle}
                    </h3>
                    <p className="service-card-two__text">
                      {service.tiledescription}
                    </p>
                    {/* <Link
                      to={service.tilelink}
                      className="service-card-two__link"
                    >
                      More Detail
                    </Link> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Safe;
