import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

function Company() {
  const [companyData, setCompantData] = useState({});

  // getdata
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyaboutcompanysection"
      );
      console.log(response.data);
      setCompantData(response.data[0]);
    } catch (error) {}
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <>
      <section className="about-four pt-120 pb-120">
        <div className="about-four__shape"></div>
        <div className="container">
          <div className="row row-gutter-y-50">
            <div className="col-lg-6">
              <div className="about-four__content">
                <div className="block-title text-left">
                  <p className="block-title__tagline">
                    {companyData.titlecompany}
                  </p>
                  <h2 className="block-title__title">
                    {companyData.headingcompany}
                  </h2>
                </div>
                <div className="about-four__box">
                  <div className="about-four__box__icon">
                    <i className="icon-loan"></i>
                  </div>
                  <div className="about-four__box__content">
                    <h3 className="about-four__box__title">
                      {companyData.pinchcompany}
                    </h3>
                  </div>
                </div>
                <p className="about-four__text">
                  {companyData.pinchdetailcompany}
                </p>

                <div className="row row-gutter-y-20">
                  <div className="col-md-6">
                    <div className="about-four__feature">
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation"></i>
                        </div>
                        <h3 className="about-four__feature__title">
                          {companyData.lefttitlecompany}
                        </h3>
                      </div>
                      <div className="about-four__feature__text">
                        {companyData.leftdetailcompany}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-four__feature">
                      <div className="about-four__feature__content">
                        <div className="about-four__feature__icon">
                          <i className="icon-confirmation"></i>
                        </div>
                        <h3 className="about-four__feature__title">
                          {companyData.righttitlecompany}
                        </h3>
                      </div>
                      <div className="about-four__feature__text">
                        {companyData.rightdetailcompany}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="about-four__btns">
                  <Link
                    to={companyData.buttontitlecompany}
                    className="thm-btn thm-btn--dark-hover"
                  >
                    Discover More
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-four__image">
                <div className="about-four__image__bg"></div>
                <div className="about-four__image__shape"></div>
                <img
                  src={`https://api.dremerz.net/uploads/${companyData?.additionalImage}`}
                  alt="Slider Image"
                />
                <div className="about-four__image__caption">
                  <h3 className="about-four__image__caption__year">
                    {companyData.yearscompany}
                    <i>{companyData.yearssymbolcompany}</i>
                  </h3>
                  <p className="about-four__image__caption__text">
                    {companyData.yearstextcompany}{" "}
                  </p>
                </div>
                <div className="about-four__image__floated">Finance</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Company;
