import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

function ContactList() {
  const [contacts, setContacts] = useState([]);

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(contacts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contacts");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const file = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contacts.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    alert("download successfully");
  };

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const response = await axios.get(
          "https://api.dremerz.net/api/mybankshoppyuserlist"
        );
        setContacts(response.data.reverse());
      } catch (error) {
        console.error("There was an error fetching the contacts!", error);
      }
    };

    fetchContacts();
  }, []);

  return (
    <div className="container my-5">
      <h2
        className="text-center mb-4"
        style={{
          color: "#E63928",
        }}
      >
        Enquire Details
      </h2>
      <button
        className="btn mb-4"
        style={{
          backgroundColor: "#E63928",
          color: "#fff",
          border: "none",
        }}
        onClick={handleExport}
      >
        Download Excel
      </button>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="table-header">
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Subject</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact.id}>
                <td>{contact.id}</td>
                <td>{contact.name}</td>
                <td>{contact.email}</td>
                <td>{contact.phone}</td>
                <td>{contact.subject}</td>
                <td>{contact.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ContactList;
