import React, { useState, useEffect } from "react";
import axios from "axios";

function Get() {
  const [aboutData, setAboutData] = useState({});

  //get data
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyaboutuspagesection"
      );
      console.log(response.data);
      setAboutData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <>
      <section className="about-two pt-120 pb-120">
        <div className="container">
          <div>
            <div className="col-lg-6">
              <div className="about-two__content">
                <div className="block-title text-left">
                  <p className="block-title__tagline">{aboutData.input3}</p>
                  <h2 className="block-title__title">{aboutData.input4} </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-gutter-y-50">
            <div className="col-lg-6">
              <div className="about-two__image">
                <img
                  src={`https://api.dremerz.net/uploads/${aboutData.image}`}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-two__content">
                {/* <div className="block-title text-left">
                  <p className="block-title__tagline">{aboutData.input3}</p>
                  <h2 className="block-title__title">{aboutData.input4} </h2>
                </div> */}
                <p className="about-two__text">{aboutData.input5}</p>
                <ul className="list-unstyled ml-0 about-two__list">
                  <li>
                    <i className="fa fa-arrow-circle-right"></i>
                    {aboutData.input6}
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right"></i>
                    {aboutData.input7}
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right"></i>
                    {aboutData.input8}
                  </li>
                  <li>
                    <i className="fa fa-arrow-circle-right"></i>
                    {aboutData.input9}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Get;
