import { useState } from "react";
import "./Register.css"; // Custom styles
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../Admin/Servicesadmin";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [userid, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleInput = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobile(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const FormData = {
      username,
      email,
      mobile,
      userid,
      password,
      totalLeads: 0,
      totalCommissions: 0,
      totalCommissionPending: 0,
    };

    try {
      const response = await axios.post(
        "https://api.dremerz.net/api/mybankshoppyuserlistforleads",
        FormData
      );

      if (response.status === 200) {
        alert("Registration Successfull");
        navigate("/login"); // Adjust the path as needed
      } else {
        alert("Registration Failed Try Again");
      }
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="register-wrapper">
      <div className="register-logo">
        <img
          src="assets/images/favicons/mybankshoppylogotitle.png"
          alt="mybankshoppy logo"
        />
      </div>
      <div className="text-center mt-4 name">
        Registration Form
        <p style={{ fontSize: "12px" }}>
          Register Now and Take the First Step Towards Your Dream Goals!
        </p>
      </div>

      <form className="p-3 mt-3" onSubmit={handleSubmit}>
        <div className="form-field d-flex align-items-center">
          <span className="far fa-user"></span>
          <input
            type="text"
            name="username"
            id="username"
            placeholder="Username"
            value={username}
            autoComplete="off"
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </div>
        <div className="form-field d-flex align-items-center">
          <span className="far fa-envelope"></span>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={email}
            autoComplete="off"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-field d-flex align-items-center">
          <span className="fas fa-phone-volume"></span>
          <input
            type="text"
            name="mobile"
            id="mobile"
            placeholder="Mobile"
            value={mobile}
            autoComplete="off"
            onChange={handleInput}
            required
            minLength="10"
            maxLength="10" // Optional: limit length to 10 digits
          />
        </div>

        <div className="form-field d-flex align-items-center">
          <span className="far fa-id-badge"></span>
          <input
            type="text"
            name="userid"
            id="userid"
            placeholder="User ID"
            value={userid}
            autoComplete="off"
            onChange={(e) => setUserId(e.target.value)}
            required
          />
        </div>
        <div className="form-field d-flex align-items-center">
          <span className="fas fa-key"></span>
          <input
            type={isPasswordVisible ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className={`fas ${isPasswordVisible ? "fa-eye" : "fa-eye-slash"}`}
            onClick={togglePasswordVisibility}
            style={{ cursor: "pointer", marginLeft: "15px" }} // Adjust as needed
          ></span>
        </div>

        <button className="btn mt-3" type="submit" disabled={loading}>
          {loading ? <Spinner /> : "Register"}
        </button>
      </form>
      <div className="text-center fs-6">
        {/* <a href="#">Forget password?</a> */}
        Already Have An Account ?{" "}
        <a href="login" style={{ marginLeft: "5px", fontSize: "16px" }}>
          Login
        </a>
      </div>
    </div>
  );
};

export default LoginForm;
