import React, { useState, useEffect } from "react";
import {
  Container,
  Form,
  Button,
  Table,
  Accordion,
  Card,
  Alert,
  Modal,
  Row,
  Col,
} from "react-bootstrap";
import axios from "axios";
import { Spinner } from "../Admin/Servicesadmin";
import "./Demoadmin.css";

const Chatbot = () => {
  const [loading, setLoading] = useState(false);
  const [totalLeads, setTotalLeads] = useState("");
  const [totalCommission, setTotalCommission] = useState("");
  const [totalCommissionPending, setTotalCommissionPending] = useState("");

  const [accordion, setAccordion] = useState([{ title: "", detail: "" }]);
  const [schedule, setSchedule] = useState([]);
  const [additionalColumn, setAdditionalColumn] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState("");
  const [heading, setHeading] = useState("");
  const [options, setOptions] = useState([""]);
  const [loanData, setLoanData] = useState({
    loanType: "",
    LoanDetails: [{ title: "", price: "" }],
  });
  const [showLoanDetails, setShowLoanDetails] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [leadsData, setLeadsData] = useState({
    totalLeads: "",
    totalCommission: "",
    totalCommissionPending: "",
  });
  const [leadsPriceData, setLeadsPriceData] = useState([]);
  const [accordianData, setAccordianData] = useState([]);
  const [sheduleData, setSheduleData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const [newDetail, setNewDetail] = useState("");

  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTableIndex, setSelectedTableIndex] = useState(null);
  const [newLoanDetails, setNewLoanDetails] = useState([]);

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [editHeading, setEditHeading] = useState("");
  const [editOptions, setEditOptions] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);

  const handleEditButtonClick = (item) => {
    setItemToEdit(item);
    setEditHeading(item.heading);
    setEditOptions(item.options);
    setShowEditDialog(true);
  };

  const handleDeleteButtonClick = (item) => {
    setItemToDelete(item);
    setShowDeleteDialog(true);
  };

  const handleSaveEdits = () => {
    const updatedItems = accordianData.map((item) =>
      item === itemToEdit
        ? { ...item, heading: editHeading, options: editOptions }
        : item
    );
    setLoading(true);

    axios
      .put(
        `https://api.dremerz.net/api/mybankshoppyleadsaccordiantable/${itemToEdit.id}`,
        {
          heading: editHeading,
          options: editOptions,
        }
      )
      .then((response) => {
        setAccordianData(updatedItems);
        setShowEditDialog(false);
      })
      .catch((error) => console.error("Error saving changes:", error));
    alert("Updated Successfully");
    setLoading(false);
  };

  const handleDeleteConfirmation = () => {
    axios
      .delete(
        `https://api.dremerz.net/api/mybankshoppyleadsaccordiantable/${itemToDelete.id}`
      )
      .then((response) => {
        setAccordianData(accordianData.filter((item) => item !== itemToDelete));
        setShowDeleteDialog(false);
      })
      .catch((error) => console.error("Error deleting item:", error));
  };

  const handleEditClick = (index) => {
    setSelectedTableIndex(index);
    setNewLoanDetails(leadsPriceData[index].LoanDetails);
    setShowEditModal(true);
  };

  const handleDeleteClick = (index) => {
    setSelectedTableIndex(index);
    setShowDeleteModal(true);
  };

  const handleSaveChanges = () => {
    const updatedLeadsPriceData = [...leadsPriceData];
    updatedLeadsPriceData[selectedTableIndex].LoanDetails = newLoanDetails;
    setLoading(true);

    axios
      .put(
        `https://api.dremerz.net/api/mybankshoppyleadspricetable/${leadsPriceData[selectedTableIndex].id}`,
        {
          LoanDetails: newLoanDetails,
        }
      )
      .then((response) => {
        setLeadsPriceData(updatedLeadsPriceData);
        setShowEditModal(false);
      })
      .catch((error) => console.error(error));
    alert("Updated Successfull");
    setLoading(false);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(
        `https://api.dremerz.net/api/mybankshoppyleadspricetable/${leadsPriceData[selectedTableIndex].id}`
      )
      .then((response) => {
        const updatedLeadsPriceData = leadsPriceData.filter(
          (_, i) => i !== selectedTableIndex
        );
        setLeadsPriceData(updatedLeadsPriceData);
        setShowDeleteModal(false);
      })
      .catch((error) => console.error(error));
  };

  const handlescheduleDelete = async (id) => {
    try {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this item?"
      );
      if (!isConfirmed) {
        return;
      } else {
        setLoading(true);
        await axios.delete(
          `https://api.dremerz.net/api/mybankshoppyleadsshedule/${id}`
        );
        alert("Updated Successfully");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    setLoading(false);
  };

  const handlescheduleEdit = async (id) => {
    setLoading(true);
    try {
      await axios.put(
        `https://api.dremerz.net/api/mybankshoppyleadsshedule/${id}`,
        {
          title: newTitle,
          detail: newDetail,
        }
      );
      setEditingItem(null);
    } catch (error) {
      console.error("Error updating item:", error);
    }
    alert("Updated Successfully");
    setLoading(true);
  };

  const openEditPopup = (item) => {
    setEditingItem(item);
    setNewTitle(item.title);
    setNewDetail(item.detail);
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (!isConfirmed) {
      return;
    } else {
      axios
        .delete(
          `https://api.dremerz.net/api/mybankshoppyleadsadditionalcolumn/${id}`
        )
        .then(() => {
          setAdditionalData(additionalData.filter((item) => item.id !== id));
        })
        .catch((error) => console.error("Error deleting item:", error));
    }
  };

  const handleEdit = (id) => {
    const itemToEdit = additionalData.find((item) => item.id === id);
    setEditItem(itemToEdit);
  };

  const handleSave = () => {
    setLoading(true);
    axios
      .put(
        `https://api.dremerz.net/api/mybankshoppyleadsadditionalcolumn/${editItem.id}`,
        editItem
      )
      .then(() => {
        setAdditionalData(
          additionalData.map((item) =>
            item.id === editItem.id ? editItem : item
          )
        );
        setEditItem(null);
        alert("Updated Successfully");
      })
      .catch((error) => console.error("Error updating item:", error));
    setLoading(false);
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleDeleteLoanDetail = (index) => {
    const updatedLoanDetails = loanData.LoanDetails.filter(
      (_, i) => i !== index
    );
    setLoanData({ ...loanData, LoanDetails: updatedLoanDetails });
  };

  const getleadsdata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadscount"
    );
    console.log(response.data, "leadscount");
    setLeadsData(response.data[0]);
  };
  const getleadsPricedata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadspricetable"
    );
    var data = response.data?.map((item) => {
      item.LoanDetails = JSON.parse(item.LoanDetails);
      return item;
    });
    console.log(response.data, data, "pricetable");

    setLeadsPriceData(data);
  };

  const getaccordiandata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadsaccordiantable"
    );
    var accordiandata = response.data?.map((item) => {
      item.options = JSON.parse(item.options);
      return item;
    });

    console.log(accordiandata, "getaccordiandata");
    setAccordianData(accordiandata);
  };

  const getsheduledata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadsshedule"
    );
    console.log(response.data, "sheduledata");
    setSheduleData(response.data);
  };
  const getadditionaldata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadsadditionalcolumn"
    );
    console.log(response.data, "getadditionaldata");

    setAdditionalData(response.data);
  };

  const handleAdditionalChange = (index, e, field) => {
    const newAdditionalColumn = [...additionalColumn];
    newAdditionalColumn[index][field] = e.target.value;
    setAdditionalColumn(newAdditionalColumn);
  };
  const addAdditionalColumn = () => {
    setAdditionalColumn([...additionalColumn, { heading: "", title: "" }]);
  };
  const removeAdditionalColumn = (index) => {
    const newAdditionalColumn = additionalColumn.filter((_, i) => i !== index);
    setAdditionalColumn(newAdditionalColumn);
  };

  const handleadditionalcolumn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      for (const item of additionalColumn) {
        await axios.post(
          "https://api.dremerz.net/api/mybankshoppyleadsadditionalcolumn",
          item
        );
        alert("Additional Column Updated");
        setLoading(false);
      }
      console.log("All columns submitted successfully");
    } catch (error) {
      console.error("Error submitting columns", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoanData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleshedulesubmit = async () => {
    setLoading(true);
    for (const item of schedule) {
      const response = await axios.post(
        "https://api.dremerz.net/api/mybankshoppyleadsshedule",
        item
      );
      alert("Updated Successfully");
      setLoading(false);
    }
  };

  const handleLoanDetailsChange = (index, e) => {
    const { name, value } = e.target;
    const newLoanDetails = [...loanData.LoanDetails];
    newLoanDetails[index] = {
      ...newLoanDetails[index],
      [name]: value,
    };
    setLoanData((prevData) => ({
      ...prevData,
      LoanDetails: newLoanDetails,
    }));
  };

  const handleAddLoan = () => {
    setShowLoanDetails(true);
  };

  const handleAddLoanDetail = () => {
    setLoanData((prevData) => ({
      ...prevData,
      LoanDetails: [...prevData.LoanDetails, { title: "", price: "" }],
    }));
  };

  const handleloanSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("loanType", loanData.loanType);
      formData.append("LoanDetails", JSON.stringify(loanData.LoanDetails));
      setLoading(true);

      try {
        const response = await axios.post(
          "https://api.dremerz.net/api/mybankshoppyleadspricetable",
          formData
        );
        alert("Loan Type Added Successfully");
        console.log(response.data);
      } catch (error) {}
      setLoading(false);

      setPreviewData([...previewData, { ...loanData, id: Date.now() }]);

      setLoanData({
        loanType: "",
        LoanDetails: [{ title: "", price: "" }],
      });
      setShowLoanDetails(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteRow = (id) => {
    setPreviewData(previewData.filter((data) => data.id !== id));
  };

  // Handle heading change
  const handleHeadingChange = (e) => {
    setHeading(e.target.value);
  };

  const handleOptionChange = (index, e) => {
    const newOptions = options.map((option, idx) => {
      if (index === idx) {
        return e.target.value;
      }
      return option;
    });
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleaccordiansubmit = async (e) => {
    e.preventDefault();

    const formData = {
      heading,
      options,
    };
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.dremerz.net/api/mybankshoppyleadsaccordiantable",
        formData
      );
      if (response.status === 200) {
        alert("Updated successfully!");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error sending data:", error);
      alert("Failed to send data.");
    }
  };

  const Updateleadscount = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.put(
        "https://api.dremerz.net/api/mybankshoppyleadscount/1/",
        leadsData
      );
      alert("Leads Count Updated Successfully");
    } catch (error) {
      console.error("Error updating leads count:", error);
    }
    setLoading(false);
  };

  const handleInputChange = (e, field) => {
    setLeadsData({
      ...leadsData,
      [field]: e.target.value,
    });
  };

  const handleAccordionChange = (index, e, field) => {
    const newAccordion = [...accordion];
    newAccordion[index][field] = e.target.value;
    setAccordion(newAccordion);
  };

  const handleScheduleChange = (index, e, field) => {
    const newSchedule = [...schedule];
    newSchedule[index][field] = e.target.value;
    setSchedule(newSchedule);
  };

  const addAccordion = () =>
    setAccordion([...accordion, { title: "", detail: "" }]);
  const removeAccordion = (index) =>
    setAccordion(accordion.filter((_, i) => i !== index));

  const addSchedule = () =>
    setSchedule([...schedule, { title: "", detail: "" }]);
  const removeSchedule = (index) =>
    setSchedule(schedule.filter((_, i) => i !== index));

  useEffect(() => {
    getleadsdata();
    getleadsPricedata();
    getaccordiandata();
    getsheduledata();
    getadditionaldata();
  }, []);

  return (
    <Container>
      <br></br>
      <h2 style={{ textAlign: "center" }}>Loan Price</h2>
      <div className="container mt-5">
        <form>
          <div className="form-group">
            <label htmlFor="loanType">Loan Type:</label>
            <input
              type="text"
              className="form-control"
              id="loanType"
              name="loanType"
              value={loanData.loanType}
              onChange={handleChange}
              placeholder="Enter Loan Type"
            />
          </div>
          <button
            type="button"
            className="btn btn-primary mt-3"
            onClick={handleAddLoan}
          >
            Add Leads Price
          </button>

          {showLoanDetails && (
            <div className="mt-4">
              {loanData.LoanDetails.map((detail, index) => (
                <div className="form-group" key={index}>
                  <div className="mb-3">
                    <label htmlFor={`detailTitle-${index}`}>Leads Count:</label>
                    <input
                      type="text"
                      className="form-control"
                      id={`detailTitle-${index}`}
                      name="title"
                      value={detail.title}
                      onChange={(e) => handleLoanDetailsChange(index, e)}
                      placeholder="Enter Leads Count"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor={`detailPrice-${index}`}>Price:</label>
                    <input
                      type="number"
                      className="form-control"
                      id={`detailPrice-${index}`}
                      name="price"
                      value={detail.price}
                      onChange={(e) => handleLoanDetailsChange(index, e)}
                      placeholder="Enter Price"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger mt-2"
                    onClick={() => handleDeleteLoanDetail(index)}
                  >
                    Delete Detail
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="btn btn-secondary mt-3"
                onClick={handleAddLoanDetail}
              >
                Add Another Loan Price
              </button>
            </div>
          )}
          {showLoanDetails && (
            <button
              type="button"
              className="btn btn-success mt-3"
              onClick={handleloanSubmit}
            >
              {loading ? <Spinner /> : "Submit"}
            </button>
          )}
        </form>
      </div>

      {/* preview loan details */}
      <Container>
        <Row>
          {leadsPriceData?.map((item, index) => (
            <Col lg={6} key={index}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan="3">{item.loanType}</th>
                  </tr>
                  <tr>
                    <th>Title</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {item.LoanDetails?.map((value, i) => (
                    <tr key={i}>
                      <td>{value.title}</td>
                      <td>{value.price}</td>
                    </tr>
                  ))}
                  <Button onClick={() => handleEditClick(index)}>Edit</Button>
                  <Button
                    onClick={() => handleDeleteClick(index)}
                    variant="danger"
                    style={{ marginLeft: "5px" }}
                  >
                    Delete
                  </Button>
                </tbody>
              </Table>
            </Col>
          ))}
        </Row>

        {/* Edit Modal */}
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {newLoanDetails.map((detail, idx) => (
                <div key={idx}>
                  <Form.Group controlId={`formTitle${idx}`}>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={detail.title}
                      onChange={(e) => {
                        const updatedDetails = [...newLoanDetails];
                        updatedDetails[idx].title = e.target.value;
                        setNewLoanDetails(updatedDetails);
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId={`formPrice${idx}`}>
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="text"
                      value={detail.price}
                      onChange={(e) => {
                        const updatedDetails = [...newLoanDetails];
                        updatedDetails[idx].price = e.target.value;
                        setNewLoanDetails(updatedDetails);
                      }}
                    />
                  </Form.Group>
                  <hr />
                </div>
              ))}
              <Button variant="primary" onClick={handleSaveChanges}>
                {loading ? <Spinner /> : "Update"}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this table's data?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleConfirmDelete}
              style={{ zIndex: "999" }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>

      <hr style={{ border: "2px solid #E63928" }} />
      <h2 style={{ textAlign: "center" }}>Accordion</h2>
      <div className="container mt-4">
        <form onSubmit={handleaccordiansubmit}>
          {/* Heading Field */}
          <div className="form-group">
            <label htmlFor="heading">Heading</label>
            <input
              type="text"
              className="form-control"
              id="heading"
              value={heading}
              onChange={handleHeadingChange}
              placeholder="Enter Heading"
              required
            />
          </div>

          {/* optionsfields */}
          {options.map((option, index) => (
            <div
              className="form-group d-flex align-items-center mb-2"
              key={index}
            >
              <div className="flex-grow-1">
                <label htmlFor={`option-${index}`}>Option {index + 1}</label>
                <input
                  type="text"
                  className="form-control"
                  id={`option-${index}`}
                  value={option}
                  onChange={(e) => handleOptionChange(index, e)}
                  placeholder="Enter Option"
                  required
                />
              </div>

              <Button
                variant="warning"
                onClick={() => handleRemoveOption(index)}
                className="accordian-warning-button"
              >
                Delete
              </Button>
            </div>
          ))}
          <br></br>

          <Button className="btn btn-primary" onClick={handleAddOption}>
            Add New Option
          </Button>
          <br></br>
          <br></br>

          <div className="text-start">
            <button type="submit" className="btn btn-success">
              {loading ? <Spinner /> : "Submit"}
            </button>
          </div>
        </form>
        <hr></hr>
      </div>
      {/* accordianData preview */}
      <Container>
        <div className="container mt-4">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Heading</th>
                <th>Options</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {accordianData.map((item, index) => (
                <tr key={index}>
                  <td>{item.heading}</td>
                  <td>
                    <ul>
                      {item.options.map((option, idx) => (
                        <li key={idx}>{option}</li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handleEditButtonClick(item)}
                      className="me-2"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteButtonClick(item)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Edit Dialog */}
        <Modal show={showEditDialog} onHide={() => setShowEditDialog(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formEditHeading">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  value={editHeading}
                  onChange={(e) => setEditHeading(e.target.value)}
                />
              </Form.Group>
              {editOptions.map((option, idx) => (
                <Form.Group key={idx} controlId={`formOption${idx}`}>
                  <Form.Label>Option {idx + 1}</Form.Label>
                  <Form.Control
                    type="text"
                    value={option}
                    onChange={(e) => {
                      const newOptions = [...editOptions];
                      newOptions[idx] = e.target.value;
                      setEditOptions(newOptions);
                    }}
                  />
                  <Button
                    variant="danger"
                    onClick={() => {
                      const newOptions = editOptions.filter(
                        (_, i) => i !== idx
                      );
                      setEditOptions(newOptions);
                    }}
                  >
                    Delete
                  </Button>
                </Form.Group>
              ))}
              <Button
                variant="secondary"
                onClick={() => setEditOptions([...editOptions, ""])}
              >
                Add New
              </Button>
              <Button variant="primary" onClick={handleSaveEdits}>
                {loading ? <Spinner /> : "Update"}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Delete Confirmation Dialog */}
        <Modal
          show={showDeleteDialog}
          onHide={() => setShowDeleteDialog(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteDialog(false)}
              style={{ zIndex: "999" }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleDeleteConfirmation}
              style={{ zIndex: "999" }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <br></br>

      <hr style={{ border: "2px solid #E63928" }} />

      <h2 style={{ textAlign: "center" }}>Schedule</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Detail</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((item, index) => (
            <tr key={index}>
              <td>
                <Form.Control
                  type="text"
                  value={item.title}
                  onChange={(e) => handleScheduleChange(index, e, "title")}
                  placeholder="Enter Title"
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  value={item.detail}
                  onChange={(e) => handleScheduleChange(index, e, "detail")}
                  placeholder="Enter Details"
                />
              </td>
              <td>
                <Button variant="warning" onClick={() => removeSchedule(index)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan="3">
              <Button variant="primary" onClick={addSchedule}>
                Add Schedule
              </Button>
            </td>
          </tr>
          <Button type="button" onClick={handleshedulesubmit}>
            {loading ? <Spinner /> : "Submit"}
          </Button>
        </tbody>
      </Table>
      {/* sheduleData preview*/}
      <div className="container mt-4">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Title</th>
              <th>Detail</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sheduleData?.map((item) => (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.detail}</td>
                <td>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handlescheduleDelete(item.id)}
                  >
                    {loading ? <Spinner /> : "Delete"}
                  </button>
                  <button
                    className="btn btn-primary btn-sm ml-2"
                    onClick={() => openEditPopup(item)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {editingItem && (
          <div className="modal fade show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Item</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setEditingItem(null)}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label htmlFor="titleInput">Title:</label>
                      <input
                        type="text"
                        className="form-control"
                        id="titleInput"
                        value={newTitle}
                        onChange={(e) => setNewTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="detailTextarea">Detail:</label>
                      <textarea
                        className="form-control"
                        id="detailTextarea"
                        rows="3"
                        value={newDetail}
                        onChange={(e) => setNewDetail(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setEditingItem(null)}
                    style={{ zIndex: "999" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handlescheduleEdit(editingItem.id)}
                    style={{ zIndex: "999" }}
                  >
                    {loading ? <Spinner /> : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <br></br>
      <hr style={{ border: "2px solid #E63928" }} />

      <h2 style={{ textAlign: "center" }}>Additional Tile</h2>
      <Form onSubmit={handleadditionalcolumn}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Heading</th>
              <th>Details</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {additionalColumn.map((item, index) => (
              <tr key={index}>
                <td>
                  <Form.Control
                    type="text"
                    value={item.heading}
                    placeholder="Enter Heading"
                    onChange={(e) =>
                      handleAdditionalChange(index, e, "heading")
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    type="text"
                    value={item.title}
                    placeholder="Enter Details"
                    onChange={(e) => handleAdditionalChange(index, e, "title")}
                  />
                </td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => removeAdditionalColumn(index)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="2">
                <Button variant="primary" onClick={addAdditionalColumn}>
                  Add Additional Column
                </Button>
              </td>
              <td>
                <Button type="submit">
                  {loading ? <Spinner /> : "Submit"}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Form>
      {/* aditionalpreview */}
      <div className="container mt-4">
        <table className="table table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th>Heading</th>
              <th>Title</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {additionalData.map((item) => (
              <tr key={item.id}>
                <td>{item.heading}</td>
                <td>{item.title}</td>
                <td>
                  <button
                    className="btn btn-primary btn-sm me-2"
                    onClick={() => handleEdit(item.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(item.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {editItem && (
          <div
            className="modal show d-block"
            style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Item</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setEditItem(null)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">Heading:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={editItem.heading}
                      onChange={(e) =>
                        setEditItem({ ...editItem, heading: e.target.value })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title:</label>
                    <textarea
                      className="form-control"
                      value={editItem.title}
                      onChange={(e) =>
                        setEditItem({ ...editItem, title: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={() => setEditItem(null)}
                    style={{ zIndex: "999" }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{ zIndex: "999" }}
                  >
                    {loading ? <Spinner /> : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {submissionError && <Alert variant="danger">{submissionError}</Alert>}
    </Container>
  );
};

export default Chatbot;
