import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import axios from "axios";
import "swiper/swiper-bundle.min.css";
import "./Firstslider.css";

SwiperCore.use([Autoplay, Pagination]);

function Firstslider() {
  const [sliderData, setSliderData] = useState([]);
  const [deviceType, setdeviceType] = useState(
    window.innerWidth > 1024 ? "desktop" : "mobile"
  );

  const sliderOptions = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    loop: true,
  };

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyhomeslider"
      );
      console.log(response.data, "sliderdata");

      var slider = [];
      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        if (element.deviceType === deviceType) {
          slider.push(element);
        }
      }
      setSliderData(slider);
    } catch (error) {
      console.error("Error fetching slider data:", error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      {sliderData.length > 0 ? (
        <section className="slider-one">
          <Swiper {...sliderOptions}>
            {sliderData.map((image, index) => (
              <SwiperSlide key={index} className="item">
                <div className="slider-one__item">
                  <img
                    src={`https://api.dremerz.net/uploads/${image.image}`}
                    alt={`Slider Image ${index + 1}`}
                    className="slider-one__image"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination"></div>
        </section>
      ) : (
        "Loading..."
      )}
    </>
  );
}

export default Firstslider;
