import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Button, Form, Modal } from "react-bootstrap";
import { Spinner } from "../Admin/Servicesadmin";

const Userleads = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobile: "",
    userid: "",
    password: "",
    totalLeads: "",
    totalCommissions: "",
    totalCommissionPending: "",
  });
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyuserlistforleads"
      );
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (editId) {
        await axios.put(
          `https://api.dremerz.net/api/mybankshoppyuserlistforleads/${editId}`,
          formData
        );
        alert("Updated Successfully");
      } else {
        await axios.post(
          "https://api.dremerz.net/api/mybankshoppyuserlistforleads",
          formData
        );
      }
      setShowModal(false);
      fetchUsers();
    } catch (error) {
      console.error("Error saving user:", error);
    }
    setLoading(false);
  };

  const handleEdit = (user) => {
    setFormData(user);
    setEditId(user.id);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!isConfirmed) {
      return;
    } else {
      try {
        await axios.delete(
          `https://api.dremerz.net/api/mybankshoppyuserlistforleads/${id}`
        );
        fetchUsers();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
  };

  return (
    <div className="container">
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Create New User
      </Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>UserID</th>
            <th>Total Leads</th>
            <th>Total Commissions</th>
            <th>Pending Commissions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users
            .slice()
            .reverse()
            .map((user) => (
              <tr key={user._id}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.mobile}</td>
                <td>{user.userid}</td>
                <td>{user.totalLeads}</td>
                <td>{user.totalCommissions}</td>
                <td>{user.totalCommissionPending}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEdit(user)}>
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(user.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editId ? "Edit User" : "Add New User"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formUserid">
              <Form.Label>UserID</Form.Label>
              <Form.Control
                type="text"
                name="userid"
                value={formData.userid}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTotalLeads">
              <Form.Label>Total Leads</Form.Label>
              <Form.Control
                type="number"
                name="totalLeads"
                value={formData.totalLeads}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTotalCommissions">
              <Form.Label>Total Commissions</Form.Label>
              <Form.Control
                type="number"
                name="totalCommissions"
                value={formData.totalCommissions}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formTotalCommissionPending">
              <Form.Label>Total Commission Pending</Form.Label>
              <Form.Control
                type="number"
                name="totalCommissionPending"
                value={formData.totalCommissionPending}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            style={{ zIndex: "999" }}
          >
            Submit{loading ? <Spinner /> : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Userleads;
