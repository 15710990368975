import AnimatedNumber from "animated-number-react";
import React, { useState, useEffect } from "react";
import axios from "axios";

function Counter() {
  const [counterData, setCounterData] = useState({});
  // getdata
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppycountersection"
      );
      console.log(response.data, "test");
      setCounterData(response.data[0]);
    } catch (error) {}
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <>
      <section className="fact-one pt-140 pb-100">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-3 col-md-6">
              <div className="fact-one__item">
                <div className="fact-one__count">
                  <span className="count-box">
                    <span
                      className="count-text"
                      data-stop="90"
                      data-speed="1500"
                    >
                      <AnimatedNumber
                        value={counterData.input1}
                        duration={5000}
                        formatValue={(v) => Math.round(v)}
                      />
                    </span>
                  </span>
                  {counterData.input2}
                </div>
                <h3 className="fact-one__title">{counterData.input3}</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="fact-one__item">
                <div className="fact-one__count">
                  <span className="count-box">
                    <span
                      className="count-text"
                      data-stop="90"
                      data-speed="1500"
                    >
                      <AnimatedNumber
                        value={counterData.input4}
                        duration={5000}
                        formatValue={(v) => Math.round(v)}
                      />
                    </span>
                  </span>
                  {counterData.input5}
                </div>
                <h3 className="fact-one__title">{counterData.input6}</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="fact-one__item">
                <div className="fact-one__count">
                  <span className="count-box">
                    <span
                      className="count-text"
                      data-stop="90"
                      data-speed="1500"
                    >
                      <AnimatedNumber
                        value={counterData.input7}
                        duration={5000}
                        formatValue={(v) => Math.round(v)}
                      />
                    </span>
                  </span>
                  {counterData.input8}
                </div>
                <h3 className="fact-one__title">{counterData.input9}</h3>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="fact-one__item">
                <div className="fact-one__count">
                  <span className="count-box">
                    <span
                      className="count-text"
                      data-stop="290"
                      data-speed="1500"
                    >
                      <AnimatedNumber
                        value={counterData.input10}
                        duration={5000}
                        formatValue={(v) => Math.round(v)}
                      />
                    </span>
                  </span>
                </div>
                <h3 className="fact-one__title">{counterData.input12}</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Counter;
