import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Link } from "react-router-dom";

// Spinner Component
export const Spinner = () => (
  <div className="spinner-container">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const AddLoanOption = () => {
  const [loanData, setLoanData] = useState({
    title: "",
    icon: "",
    description: "",
    link: "/withcompare",
    img: "",
  });
  const [aboutUs, setAbouUs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [loanOptions, setLoanOptions] = useState([]);
  const [abouUsBannerData, setAbouUsBannerData] = useState(null);
  const [deviceType, setDeviceType] = useState("desktop");

  const handleDeviceChange = (e) => {
    setDeviceType(e.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoanData({ ...loanData, [name]: value });
  };

  const handleaboutusBannerChange = (e) => {
    setAbouUs(e.target.files[0]);
  };

  const getaboutusbanner = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppybredcom"
      );
      var newBaner = response.data.filter((item) => item.title == "SERVICES");
      console.log(newBaner);
      setAbouUsBannerData(newBaner);
    } catch (error) {
      console.log(error);
    }
  };

  const handleaboutusUpload = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      const handleaboutussubmit = new FormData();
      handleaboutussubmit.append("title", "SERVICES");
      handleaboutussubmit.append("image", aboutUs);
      handleaboutussubmit.append("device", deviceType);

      const response = await axios.put(
        `https://api.dremerz.net/api/mybankshoppybredcom/${
          deviceType == "desktop" ? "4" : "3"
        }/`,
        handleaboutussubmit
      );

      if (response.status === 200) {
        alert("About us section updated successfully");
        getaboutusbanner();
      } else {
        setMessage("Failed to update About us section");
      }
    } catch (error) {
      console.error("Error updating about us section:", error);
      setMessage("Error updating about us section");
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setLoanData({ ...loanData, img: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const tiledatatoupdate = new FormData();
    Object.keys(loanData).forEach((key) => {
      tiledatatoupdate.append(key, loanData[key]);
    });

    try {
      const response = await axios.post(
        "https://api.dremerz.net/api/mybankshoppyservicepagesection",
        tiledatatoupdate
      );

      if (response.status === 200) {
        alert("Loan option added successfully!");
        setLoanData({
          title: "",
          icon: "",
          description: "",
          link: "/withcompare",
          img: "",
        });

        const fetchLoanOptions = async () => {
          try {
            const response = await axios.get(
              "https://api.dremerz.net/api/mybankshoppyservicepagesection"
            );
            setLoanOptions(response.data);
          } catch (error) {
            console.error("Error fetching loan options:", error);
            setMessage("Error fetching loan options");
          }
        };

        fetchLoanOptions();
      } else {
        setMessage("Failed to add loan option.");
      }
    } catch (error) {
      console.error("Error adding loan option:", error);
      setMessage("Error adding loan option");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (!confirm) {
      return;
    } else {
      setLoading(true);
      setMessage("");

      try {
        const response = await axios.delete(
          `https://api.dremerz.net/api/mybankshoppyservicepagesection/${id}`
        );

        if (response.status === 200) {
          alert("Loan option deleted successfully!");

          const fetchLoanOptions = async () => {
            try {
              const response = await axios.get(
                "https://api.dremerz.net/api/mybankshoppyservicepagesection"
              );
              setLoanOptions(response.data);
            } catch (error) {
              console.error("Error fetching loan options:", error);
              setMessage("Error fetching loan options");
            }
          };

          fetchLoanOptions();
        } else {
          setMessage("Failed to delete loan option.");
        }
      } catch (error) {
        console.error("Error deleting loan option:", error);
        setMessage("Error deleting loan option");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchLoanOptions = async () => {
      try {
        const response = await axios.get(
          "https://api.dremerz.net/api/mybankshoppyservicepagesection"
        );
        setLoanOptions(response.data);
      } catch (error) {
        console.error("Error fetching loan options:", error);
        setMessage("Error fetching loan options");
      }
    };

    fetchLoanOptions();
    getaboutusbanner();
  }, []);

  return (
    <div className="container my-5">
      <Link className="btn btn-primary float-end" to="/addbank">
        Add Bank
      </Link>

      <div>
        <h2 style={{ textAlign: "center" }}>Service Banner</h2>
        <div className="form-group">
          <label htmlFor="deviceType" className="form-label">
            Select Device Type:
          </label>
          <select
            id="deviceType"
            className="form-control"
            value={deviceType}
            onChange={handleDeviceChange}
          >
            <option value="desktop">Desktop</option>
            <option value="mobile">Mobile</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="image">Upload Image about us</label>
          <input
            type="file"
            id="image"
            name="image"
            className="form-control"
            onChange={handleaboutusBannerChange}
          />
          <button
            className="btn btn-primary mt-3"
            onClick={handleaboutusUpload}
          >
            {loading ? <Spinner /> : "Upload Banner"}
          </button>

          <br></br>
          {abouUsBannerData?.map((item) => {
            return item.device === deviceType ? (
              <img
                src={`https://api.dremerz.net/uploads/${item.image}`}
                style={{ height: "100px", width: "300px" }}
              />
            ) : (
              ""
            );
          })}
        </div>
      </div>
      <hr style={{ border: "2px solid #E63928" }} />

      <br></br>

      <h2 style={{ textAlign: "center" }}>Add New Loan Option</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={loanData.title}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Uncomment and use if icon field is needed */}
        {/* <div className="mb-3">
          <label className="form-label">Icon</label>
          <input
            type="text"
            className="form-control"
            name="icon"
            value={loanData.icon}
            onChange={handleInputChange}
            required
          />
        </div> */}

        <div className="mb-3">
          <label className="form-label">Description</label>
          <textarea
            className="form-control"
            name="description"
            value={loanData.description}
            onChange={handleInputChange}
            required
          ></textarea>
        </div>

        <div className="mb-3">
          <label className="form-label">Image</label>
          <input
            type="file"
            className="form-control"
            onChange={handleImageChange}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">
          {loading ? <Spinner /> : "Add Loan Option"}
        </button>
      </form>

      {message && (
        <div
          className={`alert mt-3 ${
            message.includes("successfully") ? "alert-success" : "alert-danger"
          }`}
        >
          {message}
        </div>
      )}
      <hr style={{ border: "2px solid #E63928" }} />

      <h2 className="mt-5" style={{ textAlign: "center" }}>
        Existing Loan Options
      </h2>
      <br></br>
      <div className="row">
        {loanOptions.length > 0 ? (
          loanOptions.map((option) => (
            <div className="col-md-4 mb-3" key={option.id}>
              <div className="card">
                {option.img && (
                  <img
                    src={`https://api.dremerz.net/uploads/${option.img}`}
                    className="card-img-top"
                    alt="Loan Option"
                    style={{ maxHeight: "200px", objectFit: "cover" }}
                  />
                )}
                <div className="card-body">
                  <h5 className="card-title">{option.title}</h5>
                  <p className="card-text">{option.description}</p>

                  <button
                    className="btn btn-danger mt-3"
                    onClick={() => handleDelete(option.id)}
                  >
                    {loading ? <Spinner /> : "Delete"}
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No loan options available.</p>
        )}
      </div>
      <hr style={{ border: "2px solid #E63928" }} />
    </div>
  );
};

export default AddLoanOption;
