import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Table, Modal, Form } from "react-bootstrap";
import { Spinner } from "./Servicesadmin";

const AdminPage = () => {
  const [qna, setQna] = useState([]);
  const [newQ, setNewQ] = useState("");
  const [newA, setNewA] = useState("");
  const [editQna, setEditQna] = useState(null);
  const [loading, setLoading] = useState(null);

  const addQna = () => {
    setLoading(true);
    axios
      .post("https://api.dremerz.net/api/mybankshoppychatbot", {
        question: newQ,
        answer: newA,
      })
      .then((response) => {
        setQna([...qna, response.data]);
        setNewQ("");
        setNewA("");
        alert("chatbot Updated Successfully");
      })
      .catch((error) => {
        console.error("There was an error adding the Q&A!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteQna = (id) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (!confirm) {
      return;
    } else {
      setLoading(true);
      axios
        .delete(`https://api.dremerz.net/api/mybankshoppychatbot/${id}`)
        .then(() => {
          setQna(qna.filter((item) => item.id !== id));
          alert("Chatbot Row Deleted Successfully");
        })

        .catch((error) => {
          console.error("There was an error deleting the Q&A!", error);
        })
        .finally(() => {
          setLoading(true);
        });
    }
  };

  useEffect(() => {
    axios
      .get("https://api.dremerz.net/api/mybankshoppychatbot")
      .then((response) => setQna(response.data))
      .catch((error) => {
        console.error("There was an error fetching the Q&A data!", error);
      });
  }, []);

  const saveEdit = () => {
    setLoading(true);
    axios
      .put(
        `https://api.dremerz.net/api/mybankshoppychatbot/${editQna.id}`,
        editQna
      )
      .then(() => {
        setQna(qna.map((item) => (item.id === editQna.id ? editQna : item)));
        setEditQna(null);
        alert("Edit Successfully Updated");
      })
      .catch((error) => {
        console.error("There was an error updating the Q&A!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container">
      <h2 style={{ textAlign: "center" }}>Chatbot Q & A</h2>
      <Form>
        <Form.Group>
          <Form.Label>Question</Form.Label>
          <Form.Control
            value={newQ}
            onChange={(e) => setNewQ(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Answer</Form.Label>
          <Form.Control
            value={newA}
            onChange={(e) => setNewA(e.target.value)}
          />
        </Form.Group>
        <Button onClick={addQna}>{loading ? <Spinner /> : "Add Q & A"}</Button>
      </Form>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {qna.map((item) => (
            <tr key={item.id}>
              <td>{item.question}</td>
              <td>{item.answer}</td>
              <td>
                <Button onClick={() => setEditQna(item)}>Edit</Button>
                <Button variant="danger" onClick={() => deleteQna(item.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {editQna && (
        <Modal show onHide={() => setEditQna(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Q&A</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Question</Form.Label>
                <Form.Control
                  value={editQna.question}
                  onChange={(e) =>
                    setEditQna({ ...editQna, question: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  value={editQna.answer}
                  onChange={(e) =>
                    setEditQna({ ...editQna, answer: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => saveEdit()}
              style={{ zIndex: "999" }}
            >
              {loading ? <Spinner /> : "Edit"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AdminPage;
