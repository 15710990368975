import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faInstagram,
  faYoutube,
  faLinkedin,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import emailjs from "emailjs-com";

function Contactfile() {
  const [contactData, setContactData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.dremerz.net/api/mybankshoppyuserlist",
        formData
      );
      console.log(response.data);

      // await emailjs.send(
      //   "service_fccjmtp",
      //   "template_d6u3fui",
      //   {
      //     name: formData.name,
      //     email: formData.email,
      //     phone: formData.phone,
      //     subject: formData.subject,
      //     message: formData.message,
      //   },
      //   "aJnP5PotDQdflEJWg"
      // );

      alert("Form submitted  successfully!");
    } catch (error) {
      console.error(
        "There was an error submitting the form or sending the email!",
        error
      );
      alert("There was an error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppycontactuspagesection"
      );
      console.log(response.data, "free");
      setContactData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <section className="contact-one pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="contact-one__content">
                <div className="block-title">
                  <p className="block-title__tagline">{contactData.input2}</p>
                  <h2 className="block-title__title">{contactData.input3} </h2>
                </div>
                <p className="contact-one__text">{contactData.input4} </p>

                <div className="contact-one__social">
                  <Link to="#">
                    <FontAwesomeIcon icon={faXTwitter} />
                  </Link>
                  <Link to="#">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                  <Link to="#">
                    <FontAwesomeIcon icon={faYoutube} />
                  </Link>
                  <Link to="#">
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                  <Link to="#">
                    <FontAwesomeIcon icon={faFacebook} />
                  </Link>
                  <Link to="#">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <form
                onSubmit={handleSubmit}
                className="form-one contact-form-validated"
              >
                <div className="row row-gutter-y-20 row-gutter-x-20">
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder="Full name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="email"
                      placeholder="Email address"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder="Phone number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      placeholder="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="thm-btn"
                      disabled={loading}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        "Send a Message"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactfile;
