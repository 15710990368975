import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";

function AboutusUploadForm() {
  const [aboutUsData, setAbouUsData] = useState("");
  const [aboutUs, setAbouUs] = useState(null);
  const [abouUsBannerData, setAbouUsBannerData] = useState(null);
  const [deviceType, setDeviceType] = useState("desktop");
  const [aboutUsImage1, setAboutUsImage1] = useState(null);
  const [aboutUsImage2, setAboutUsImage2] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleaboutusimage2Change = (e) => {
    setAboutUsImage2(e.target.files[0]);
  };

  const handleaboutusimageChange = (e) => {
    setAboutUsImage1(e.target.files[0]);
  };

  //aboutus onchange
  const handleaboutusChange = (e) => {
    const { name, value } = e.target;
    setAbouUsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(name, value, "aboutuschange");
  };

  const handleDeviceChange = (e) => {
    setDeviceType(e.target.value);
  };
  const handleaboutusBannerChange = async (e) => {
    setAbouUs(e.target.files[0]);
  };

  const handleaboutusUpload = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const handleaboutussubmit = new FormData();
      handleaboutussubmit.append("title", "About");
      handleaboutussubmit.append("image", aboutUs);
      handleaboutussubmit.append("device", deviceType);

      const response = await axios.put(
        `https://api.dremerz.net/api/mybankshoppybredcom/${
          deviceType == "desktop" ? "2" : "1"
        }/`,
        handleaboutussubmit
      );

      if (response.status === 200) {
        alert("About us section updated successfully");
        getaboutusbanner();
      } else {
        console.log("Failed to update About us section");
      }
    } catch (error) {
      console.error("Error updating about us section:", error);
    }
    setLoading(false);
  };

  //handle benefits submit
  const handleaboutusSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const handleaboutussubmit = new FormData();
      Object.keys(aboutUsData).forEach(async (key) => {
        if (key == "image") {
          if (aboutUsImage1) {
            const handleaboutussubmit1 = new FormData();
            handleaboutussubmit1.append("image", aboutUsImage1);

            const response1 = await axios.put(
              "https://api.dremerz.net/api/mybankshoppyaboutuspagesection/1/",
              handleaboutussubmit1
            );
          }
        } else if (key == "image1") {
          if (aboutUsImage2) {
            const handleaboutussubmit1 = new FormData();
            handleaboutussubmit1.append("image1", aboutUsImage2);

            const response1 = await axios.put(
              "https://api.dremerz.net/api/mybankshoppyaboutuspagesection/1/",
              handleaboutussubmit1
            );
          }
        } else {
          handleaboutussubmit.append(key, aboutUsData[key]);
        }
      });

      handleaboutussubmit.forEach((item, key) => {
        console.log(item, key);
      });
      console.log();

      const response = await axios.put(
        "https://api.dremerz.net/api/mybankshoppyaboutuspagesection/1/",
        handleaboutussubmit
      );

      if (response.status === 200) {
        alert("About us section updated successfully");
      } else {
        console.log("Failed to update About us section");
      }
    } catch (error) {
      console.error("Error updating about us section:", error);
    }
    setLoading(false);
  };
  //get data
  const getaboutus = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyaboutuspagesection"
      );
      console.log(response.data, "getdata");
      setAbouUsData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const getaboutusbanner = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppybredcom"
      );
      var newBaner = response.data.filter((item) => item.title == "About");
      console.log(newBaner);
      setAbouUsBannerData(newBaner);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getaboutus();
    getaboutusbanner();
  }, []);

  return (
    <>
      {/* aboutus page Banner */}
      <div className="container">
        <div>
          <h2 style={{ textAlign: "center" }}>AboutUs Banner</h2>
          <div className="form-group">
            <label htmlFor="deviceType" className="form-label">
              Select Device Type:
            </label>
            <select
              id="deviceType"
              className="form-control"
              value={deviceType}
              onChange={handleDeviceChange}
            >
              <option value="desktop">Desktop</option>
              <option value="mobile">Mobile</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="image">Upload Image aboutus </label>
            <input
              type="file"
              id="image"
              name="image"
              className="form-control"
              onChange={handleaboutusBannerChange}
            />
            <br></br>

            <button className="btn btn-primary" onClick={handleaboutusUpload}>
              {loading ? <Spinner /> : "Update"}
            </button>
            <div>
              {abouUsBannerData?.map((item) => {
                return item.device === deviceType ? (
                  <img
                    src={`https://api.dremerz.net/uploads/${item.image}`}
                    style={{ height: "100px", width: "300px" }}
                  />
                ) : (
                  ""
                );
              })}
            </div>
          </div>
          <hr style={{ border: "2px solid #E63928" }} />
        </div>

        {/* about us section */}
        <br></br>
        <h2 style={{ textAlign: "center" }}>AboutUs Update</h2>
        <form onSubmit={handleaboutusSubmit}>
          {/* Input 2 */}
          <div className="form-group">
            <label htmlFor="image1">Upload Image aboutus </label>
            <input
              type="file"
              accept="image/*"
              id="image1"
              name="image1"
              className="form-control"
              onChange={handleaboutusimageChange}
            />
            <img
              src={`https://api.dremerz.net/uploads/${aboutUsData.image}`}
              style={{ maxHeight: "100px", maxWidth: "200px" }}
            />
          </div>
          {/* Input 3 */}
          <div className="form-group">
            <label htmlFor="input3">title</label>
            <input
              type="text"
              id="input3"
              name="input3"
              className="form-control"
              value={aboutUsData.input3}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 4 */}
          <div className="form-group">
            <label htmlFor="input4">heading </label>
            <input
              type="text"
              id="input4"
              name="input4"
              className="form-control"
              value={aboutUsData.input4}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 5 */}
          <div className="form-group">
            <label htmlFor="input5">description</label>
            <input
              type="text"
              id="input5"
              name="input5"
              className="form-control"
              value={aboutUsData.input5}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 6 */}
          <div className="form-group">
            <label htmlFor="input6">arrow point 1 </label>
            <input
              type="text"
              id="input6"
              name="input6"
              className="form-control"
              value={aboutUsData.input6}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 7 */}
          <div className="form-group">
            <label htmlFor="input7">arrow point 2</label>
            <input
              type="text"
              id="input7"
              name="input7"
              className="form-control"
              value={aboutUsData.input7}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 8 */}
          <div className="form-group">
            <label htmlFor="input8">arrow point 3</label>
            <input
              type="text"
              id="input8"
              name="input8"
              className="form-control"
              value={aboutUsData.input8}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 9 */}
          <div className="form-group">
            <label htmlFor="input9">arrow point 4</label>
            <input
              type="text"
              id="input9"
              name="input9"
              className="form-control"
              value={aboutUsData.input9}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 10 */}
          <div className="form-group">
            <label htmlFor="image2">Upload Image </label>
            <input
              type="file"
              id="image2"
              name="image2"
              accept="image/*"
              className="form-control"
              onChange={handleaboutusimage2Change}
            />
            <img
              src={`https://api.dremerz.net/uploads/${aboutUsData.image1}`}
              style={{ maxHeight: "100px", maxWidth: "200px" }}
            />
          </div>
          {/* Input 11 */}
          <div className="form-group">
            <label htmlFor="input11">tag 1</label>
            <input
              type="text"
              id="input11"
              name="input11"
              className="form-control"
              value={aboutUsData.input11}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 12 */}
          <div className="form-group">
            <label htmlFor="input12">tag 2</label>
            <input
              type="text"
              id="input12"
              name="input12"
              className="form-control"
              value={aboutUsData.input12}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 13 */}
          <div className="form-group">
            <label htmlFor="input13">tag 3</label>
            <input
              type="text"
              id="input13"
              name="input13"
              className="form-control"
              value={aboutUsData.input13}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 14 */}
          <div className="form-group">
            <label htmlFor="input14">tag label</label>
            <input
              type="text"
              id="input14"
              name="input14"
              className="form-control"
              value={aboutUsData.input14}
              onChange={handleaboutusChange}
            />
          </div>
          {/* Input 15 */}
          <div className="form-group">
            <label htmlFor="input15">button link</label>
            <input
              type="text"
              id="input15"
              name="input15"
              className="form-control"
              value={aboutUsData.input15}
              onChange={handleaboutusChange}
            />
          </div>
          <br></br>
          <button type="submit" className="btn btn-primary">
            {loading ? <Spinner /> : "Update"}
          </button>
        </form>
        <hr style={{ border: "2px solid #E63928" }} />
      </div>
    </>
  );
}

export default AboutusUploadForm;
