import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import {
  ProgressBar,
  Form,
  Row,
  Col,
  Table,
  InputGroup,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Emicalculator.css";

function Emicalculator() {
  const [principal, setPrincipal] = useState(100000);
  const [rate, setRate] = useState(10);
  const [tenure, setTenure] = useState(1);
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    const R = rate / 1200;
    const N = tenure * 12;
    const emiValue =
      (principal * R * Math.pow(1 + R, N)) / (Math.pow(1 + R, N) - 1);
    setEmi(emiValue.toFixed(2));

    let remainingPrincipal = principal;
    let totalInterestPayable = 0;
    let amortizationSchedule = [];

    for (let i = 1; i <= N; i++) {
      const interestForMonth = remainingPrincipal * R;
      const principalForMonth = emiValue - interestForMonth;
      remainingPrincipal -= principalForMonth;
      totalInterestPayable += interestForMonth;

      amortizationSchedule.push({
        month: i,
        principal: principalForMonth.toFixed(2),
        interest: interestForMonth.toFixed(2),
        totalPayment: emiValue.toFixed(2),
        remainingPrincipal: remainingPrincipal.toFixed(2),
      });
    }

    setTotalInterest(totalInterestPayable.toFixed(2));
    setTotalPayment(
      (parseFloat(totalInterestPayable) + parseFloat(principal)).toFixed(2)
    );
    setSchedule(amortizationSchedule);

    createChart([
      { label: "Principal Loan Amount", value: principal },
      { label: "Total Interest", value: totalInterest },
    ]);
  }, [principal, rate, tenure, totalInterest]);

  const createChart = (data) => {
    d3.select("#chart").selectAll("*").remove();

    const width = 400;
    const height = 300;
    const radius = Math.min(width, height) / 2;

    const svg = d3
      .select("#chart")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2 - 30}, ${height / 2})`);

    const color = d3.scaleOrdinal(["#E63928", "#09DADE"]);

    const pie = d3.pie().value((d) => d.value);

    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    const data_ready = pie(data);

    svg
      .selectAll("path")
      .data(data_ready)
      .join("path")
      .attr("d", arc)
      .attr("fill", (d) => color(d.data.label))
      .style("stroke", "#fff")
      .style("stroke-width", "2px");

    svg
      .selectAll("text")
      .data(data_ready)
      .join("text")
      .text((d) => d.data.label)
      .attr("transform", (d) => `translate(${arc.centroid(d)})`)
      .style("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-weight", "bold")
      .style("fill", "#FFFFFF");
  };

  return (
    <div className="emicalc-container mt-5">
      <h2 className="text-center mb-4" style={{ color: "#E63928" }}>
        Calculate Your Loan Interest
      </h2>

      <Row className="mt-4">
        <Col md={6}>
          <Form>
            <Form.Group className="mb-4">
              <Form.Label className="emicalc-fromlabel">Loan Amount</Form.Label>

              <Form.Control
                type="range"
                min="10000"
                max="1000000"
                step="1000"
                value={principal}
                onChange={(e) => setPrincipal(Number(e.target.value))}
                className="emicalc-form-control"
              />
              <ProgressBar
                now={(principal / 1000000) * 100}
                className="mt-2 emicalc-progressbar"
              />
              <div className="mt-2">₹{principal}</div>

              <InputGroup className="mt-2 emicalc-input-group">
                <InputGroup.Text className="emicalc-input-group-text">
                  <i className="fas fa-rupee-sign emicalc-icon"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  value={principal}
                  onChange={(e) => setPrincipal(Number(e.target.value))}
                  placeholder="Enter Principal"
                  className="emicalc-form-control"
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="emicalc-fromlabel">
                Interest Rate (%)
              </Form.Label>
              <Form.Control
                type="range"
                min="1"
                max="20"
                step="0.1"
                value={rate}
                onChange={(e) => setRate(Number(e.target.value))}
                className="emicalc-form-control"
              />
              <ProgressBar
                now={(rate / 20) * 100}
                className="mt-2 emicalc-progressbar"
              />
              <div className="mt-2">{rate}%</div>

              <InputGroup className="mt-2 emicalc-input-group">
                <InputGroup.Text>
                  <i className="fas fa-percent emicalc-icon"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  value={rate}
                  onChange={(e) => setRate(Number(e.target.value))}
                  placeholder="Enter Interest Rate"
                  className="emicalc-form-control"
                />
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label className="emicalc-fromlabel">
                Loan Tenure (Years)
              </Form.Label>
              <Form.Control
                type="range"
                min="1"
                max="30"
                step="1"
                value={tenure}
                onChange={(e) => setTenure(Number(e.target.value))}
                className="emicalc-form-control"
              />
              <ProgressBar
                now={(tenure / 30) * 100}
                className="mt-2 emicalc-progressbar"
              />
              <div className="mt-2">{tenure} Years</div>

              <InputGroup className="mt-2 emicalc-input-group">
                <InputGroup.Text>
                  <i className="fas fa-calendar emicalc-icon"></i>
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  value={tenure}
                  onChange={(e) => setTenure(Number(e.target.value))}
                  placeholder="Enter Tenure"
                  className="emicalc-form-control"
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </Col>

        <Col md={6} className="text-center">
          <h4>Monthly Loan EMI: ₹{emi}</h4>
          <h5>Total Interest Payable: ₹{totalInterest}</h5>
          <h5>Total Payment: ₹{totalPayment}</h5>
          <svg id="chart" className="mt-4"></svg>
        </Col>
      </Row>

      <h3 className="text-center mt-5">Your Amortization Schedule</h3>
      <Table striped bordered hover responsive className="mt-4 emicalc-table">
        <thead>
          <tr>
            <th>Month</th>
            <th>Principal</th>
            <th>Interest</th>
            <th>Total Payment</th>
            <th>Remaining Principal</th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((payment, i) => (
            <tr key={i}>
              <td>{payment.month}</td>
              <td>₹{payment.principal}</td>
              <td>₹{payment.interest}</td>
              <td>₹{payment.totalPayment}</td>
              <td>₹{payment.remainingPrincipal}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Emicalculator;
