import React, { useEffect, useState } from "react";
import "./EmploymentType.css";
import { lab } from "d3";

function EmploymentType(props) {
  const [selectedOption, setSelectedOption] = useState(
    props?.data?.employmentType
  );

  const employmentOptions = [
    {
      label: "Salaried",
      description: "Earn regular monthly income",
      icon: "assets/images/eligibility/salaried.avif",
      id: 1,
    },
    {
      label: "Business Owner",
      description: "Run own business",
      icon: "assets/images/eligibility/businessowners.avif",
      id: 2,
    },
    {
      label: "Selfemployed Professional",
      description: "Own a practice (e.g. Doctor, C.A., etc.)",
      icon: "assets/images/eligibility/selfemployed.avif",
      id: 3,
    },
    {
      label: "Independent Worker",
      description: "e.g. driver, electrician, farmer, shopkeeper, etc.",
      icon: "assets/images/eligibility/independantworker.avif",
      id: 4,
    },
    {
      label: "Student",
      description: "",
      icon: "assets/images/eligibility/student.avif",
      id: 5,
    },
    {
      label: "Retired",
      description: "",
      icon: "assets/images/eligibility/retired.avif",
      id: 6,
    },
    {
      label: "Homemaker",
      description: "",
      icon: "assets/images/eligibility/homemaker.avif",
      id: 7,
    },
    {
      label: "Others",
      description: "",
      icon: "assets/images/eligibility/others.avif",
      id: 8,
    },
  ];

  const nextStep = (option) => {
    if (option != null) {
      props.setData((prevData) => ({
        ...prevData,
        employmentType: option,
      }));
      props.setstep(2);
    } else {
      alert("Please, select your employment type.");
    }
  };

  return (
    <div className="employment-container container">
      <h2 className="text-center mt-4 new-title">
        Compare, check your eligibility and apply for credit cards online.
      </h2>
      <div className="employment-type row justify-content-center mt-4">
        {employmentOptions.map((option) => (
          <div
            key={option.id}
            className={`employment-option col-6 col-md-3 text-center ${
              selectedOption?.id === option.id ? "selected" : ""
            }`}
            onClick={() => nextStep(option)}
          >
            <img
              src={option.icon}
              alt={option.label}
              className="employment-icon mb-2"
            />
            <div>
              <h5 className="new-eligiblity-label">{option.label}</h5>
            </div>

            <p>{option.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EmploymentType;
