import React from "react";
import First from "../Menservisedeatils/First";
import First1 from "../../Mencompare/First";

const ServiceDetails = () => {
  return (
    <>
      <First />
      <First1/>
    </>
  );
};

export default ServiceDetails;
