import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const First = () => {
  const [serviceData, setServiceData] = useState([]);
  //getdata
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyservicepagesection"
      );
      console.log(response.data, "service");
      setServiceData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <>
      <section className="service-grid pb-50" style={{ paddingTop: "0px" }}>
        <div className="container">
          <h2 className="text-center" style={{ color: "#E63928" }}>
            Our Services
          </h2>
          <br></br>
          <div className="row row-gutter-y-50">
            {/* Auto Car Loan */}
            {serviceData.map((values) => (
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-card">
                  <div className="service-card__image">
                    <img
                      src={`https://api.dremerz.net/uploads/${values?.img}`}
                    />
                    <Link to={values.link} state={{ data: values }}></Link>
                  </div>
                  <div className="service-card__content">
                    <div className="service-card__content__inner">
                      {/* <div className="service-card__icon">
                        <i className={values.icon}></i>
                      </div> */}
                      <h3 className="service-card__title">
                        <Link to={values.link} state={{ data: values }}>
                          {values.title}
                        </Link>
                      </h3>
                      <p className="service-card__text">{values.description}</p>
                      <Link
                        to={values.link}
                        state={{ data: values }}
                        className="service-card__link"
                      >
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default First;
