import React, { useState, useEffect } from "react";
import axios from "axios";

function Testimonials() {
  const [testimonialData, setTestimonialData] = useState({});
  const [testimonials, setTestimonials] = useState([]);

  // getdata
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppytestimonialreviews"
      );
      console.log(response.data, "test");
      setTestimonialData(response.data[0]);
      setTestimonials(JSON.parse(response.data[0].reviews));
    } catch (error) {}
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <section className="testimonials-one pt-120 mb--40">
        <div className="container">
          <div className="block-title text-center">
            <p className="block-title__tagline">{testimonialData.title}</p>
            <h2 className="block-title__title">
              {testimonialData.description}
            </h2>
          </div>
          <div className="row row-gutter-y-30">
            {testimonials.map((testimonial, index) => (
              <div
                className="col-lg-4 col-md-12 wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay={`${index * 100}ms`}
                key={index}
              >
                <div className="testimonial-card">
                  <div className="testimonial-card__info">
                    <div className="testimonial-card__image">
                      <img src={testimonial.img} alt={testimonial.name} />
                    </div>
                    <div className="testimonial-card__meta">
                      <div className="testimonial-card__stars">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                      </div>
                      <h3 className="testimonial-card__name">
                        {testimonial.name}
                      </h3>
                      <p className="testimonial-card__designation"></p>
                    </div>
                  </div>
                  <span className="testimonial-card__line"></span>
                  <div className="testimonial-card__text">
                    <p>{testimonial.review}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
