import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, ListGroup, Card, Form } from "react-bootstrap";
import emailjs from "emailjs-com";
import "./Chatboxnew.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const Chatbotnew = () => {
  const [qna, setQna] = useState([]);
  const [selectedQna, setSelectedQna] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [userQuestion, setUserQuestion] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const scrollableDivRef = useRef(null);
  const [isQuestion, setIsQuestion] = useState(false);

  useEffect(() => {
    axios
      .get("https://api.dremerz.net/api/mybankshoppychatbot")
      .then((response) => {
        setQna(response.data);
        setChatHistory([
          {
            isUser: true,
            message: "Welcome! How can I assist you today? ",
          },
        ]);
      })
      .catch((error) => console.error("Error fetching Q&A:", error));
  }, []);

  const createChatMessage = (isUser, message) => {
    return (
      <div
        className={`d-flex flex-row ${
          isUser ? "justify-content-start" : "justify-content-end"
        } mb-4`}
        key={message}
      >
        {isUser && (
          <img
            src="assets/images/favicons/mybankshoppylogotitle.png"
            alt="avatar 1"
            style={{ width: "45px", height: "100%" }}
          />
        )}
        <div
          className={`p-3 ${isUser ? "me-3 border bg-body-tertiary" : "ms-3"}`}
          style={{ borderRadius: "15px" }}
        >
          <p className="small mb-0">{message}</p>
        </div>
        {!isUser && (
          <img
            src="assets/images/chatbot/userchat.avif"
            alt="avatar 1"
            style={{ width: "45px", height: "100%" }}
          />
        )}
      </div>
    );
  };

  const handleQuestionClick = (item) => {
    const userMessage = { isUser: false, message: item.question };
    const botMessage = { isUser: true, message: "Loading..." };

    setChatHistory((prevChat) => [...prevChat, userMessage, botMessage]);

    setSelectedQna(item);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      const answerMessage = { isUser: true, message: item.answer };

      setChatHistory((prevChat) =>
        prevChat.map((msg) =>
          msg.message === "Loading..." ? answerMessage : msg
        )
      );
    }, 1000);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!userQuestion || !mobileNumber) {
      alert("Please fill out both fields.");
      return;
    }

    const templateParams = {
      user_question: userQuestion,
      mobile_number: mobileNumber,
    };

    emailjs
      .send("serviceid", "templateid", templateParams, "userid")
      .then((response) => {
        alert("Your question has been sent successfully!");
        setUserQuestion("");
        setMobileNumber("");
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        alert("Failed to send your question. Please try again.");
      });
  };

  const handleCloseChat = () => {
    setIsVisible(false);
  };

  const handleOpenChat = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  }, [chatHistory]);
  return (
    <>
      <div
        className={`shop-chat card ${isVisible ? "chat-show" : "chat-hide"}`}
        id="chat1"
        style={{ borderRadius: "15px" }}
      >
        <div
          className="card-header d-flex justify-content-between align-items-center p-3 bg-info text-white border-bottom-0"
          style={{
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
        >
          <p className="mb-0 fw-bold">Ask</p>
          <Button
            variant="outline-light"
            onClick={handleCloseChat}
            style={{ marginLeft: "auto", border: "none" }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </div>
        <div className="card-body" ref={scrollableDivRef}>
          {chatHistory.map((chat) =>
            createChatMessage(chat.isUser, chat.message)
          )}

          <div className="forms-chat">
            <div
              className={`questions-chat row ${
                isQuestion ? "show-q" : "hide-q"
              }`}
            >
              {qna.map((item) => (
                <div
                  className="chat-q col-auto"
                  onClick={() => handleQuestionClick(item)}
                  key={item.id}
                >
                  {item.question}
                </div>
              ))}
            </div>

            <Form onSubmit={handleFormSubmit} style={{ marginTop: "20px" }}>
              <Form.Group
                controlId="mobileNumber"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <div style={{ flex: 1 }}> */}
                <Form.Control
                  as="textarea"
                  value={userQuestion}
                  onChange={(e) => setUserQuestion(e.target.value)}
                  placeholder="Choose or Type Question  "
                  required
                  style={{
                    border: "2px solid rgb(206, 212, 218)",
                    padding: "10px 0px 0px 10px",
                    fontSize: "13px",
                    backgroundColor: "rgb(248, 249, 250)",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
                    transition: "border-color 0.3s, box-shadow 0.3s",
                    resize: "none",
                    height: "44px",
                    width: "54%",
                  }}
                  onFocus={(e) => {
                    setIsQuestion(true);
                    e.target.style.borderColor = "#4a90e2";
                  }}
                  onBlur={(e) => {
                    setTimeout(() => {
                      setIsQuestion(false);
                    }, 500);

                    e.target.style.borderColor = "#ced4da";
                  }}
                />
                <Form.Control
                  type="number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder=" MobileNumber"
                  required
                  style={{
                    borderRadius: "12px",
                    border: "2px solid #ced4da",
                    padding: "15px",
                    fontSize: "12px",
                    backgroundColor: "#f8f9fa",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    transition: "border-color 0.3s ease, box-shadow 0.3s ease",
                    resize: "none",
                    height: "44px",
                    width: "36%",
                  }}
                  onFocus={(e) => (e.target.style.borderColor = "#4a90e2")}
                  onBlur={(e) => (e.target.style.borderColor = "#ced4da")}
                />
                <Button
                  type="submit"
                  variant="primary"
                  style={{
                    height: "40px",
                    width: "10%",
                    backgroundColor: "#4a90e2",
                    border: "none",
                    padding: "10px 20px",
                    fontSize: "16px",
                    borderRadius: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    transition:
                      "background-color 0.3s ease, box-shadow 0.3s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#357ab8";
                    e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.15)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#4a90e2";
                    e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
                  }}
                  onFocus={(e) =>
                    (e.target.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.2)")
                  }
                  onBlur={(e) =>
                    (e.target.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)")
                  }
                >
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    // style={{ marginLeft: "10px", padding: "10px 10px 10px 10px" }}
                  />
                </Button>
                {/* </div> */}
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <div className="sticky" onClick={handleOpenChat}>
        <img
          src="assets/images/chatbot/chatbot5.png"
          className="stickyimage"
          alt="Chatbot Icon"
        />
      </div>
    </>
  );
};

export default Chatbotnew;
