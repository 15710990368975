import BGS1 from "../../assets/images/bankloan/icicibusinessloan.avif";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Emicalculator from "../Emicalculator/Emicalculator";
import "./Withcompare.css";

function First() {
  const [lowrate, setLowrate] = useState(0);
  const [highrate, setHighrate] = useState(0);
  const [principalrate, setPrincipalrate] = useState(50000);
  const [loanyear, setLoanyear] = useState(3);
  const [selectedBank, setSelectedBank] = useState(null);
  const [clicked, setClicked] = useState(null);
  const [bankData, setBankData] = useState(null);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [chaning, setChaning] = useState(false);

  const location = useLocation();
  const loanData = location.state?.data;

  const [serviceData, setServiceData] = useState([]);
  //getdata
  const getdataSerice = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyservicepagesection"
      );
      console.log(response.data, "service");
      setServiceData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const loancalculate = (principal_rate, interest_rates, loan_year) => {
    const loanAmount = principal_rate;
    const numberOfMonths = loan_year * 12;
    const rateOfInterest = interest_rates;
    const monthlyInterestRatio = rateOfInterest / 100 / 12;
    const top = Math.pow(1 + monthlyInterestRatio, numberOfMonths);
    const bottom = top - 1;
    const sp = top / bottom;
    const emi = loanAmount * monthlyInterestRatio * sp;
    const full = numberOfMonths * emi;
    const interest = full - loanAmount;
    const int_pge = (interest / full) * 100;

    const emi_str = emi
      .toFixed(2)
      .toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return emi_str;
  };

  const loancal = (lowInt, highInt) => {
    if (principalrate > 0 && loanyear > 0) {
      if (lowInt && highInt) {
        const low_emi = loancalculate(principalrate, lowInt, loanyear);
        const high_emi = loancalculate(principalrate, highInt, loanyear);
        // return `INR ${low_emi} - ${high_emi}`;
        return `INR ${low_emi}`;
      } else if (!lowInt && highInt) {
        const high_emi = loancalculate(principalrate, highInt, loanyear);
        return `INR ${high_emi}`;
      } else if (lowInt && !highInt) {
        const low_emi = loancalculate(principalrate, lowInt, loanyear);
        return `INR ${low_emi}`;
      }
    }
  };

  const compardata = [
    {
      id: 1,
      name: "ICICI Bank",
      lowInt: 15,
      highInt: 25,
      fees: "2%",
    },
    {
      id: 2,
      name: "HDFC Bank",
      lowInt: 15,
      highInt: 24,
      fees: "2%",
    },
    {
      id: 3,
      name: "AXIS Bank",
      lowInt: 15,
      highInt: 24,
      fees: "2%",
    },
    {
      id: 4,
      name: "IDFC Bank",
      lowInt: 17,
      highInt: 28,
      fees: "2.50%",
    },
    {
      id: 5,
      name: "NBFC Bank",
      lowInt: 15,
      highInt: 28,
      fees: "2%",
    },
  ];

  const setSelectedBankDetail = (bank) => {
    console.log(bank, "new");
    setChaning(true);
    setTimeout(() => {
      setSelectedBank(bank);

      const section = document.getElementById(`bank_det`);
      const section1 = document.getElementById(`bank_menu`);
      console.log(
        section.offsetHeight,
        section1.offsetHeight,
        window.innerWidth
      );

      if (section1 && window.innerWidth < 767) {
        //   const sectionTop = section.offsetTop;
        window.scrollTo({
          top: section1.offsetHeight + 80,
          behavior: "smooth",
        });
      }

      // document.getElementById("bank_det").scrollIntoView("smooth");
    }, 300);

    setTimeout(() => {
      setChaning(false);
    }, 500);
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyaddbankloans"
      );

      const bankList = [];

      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        console.log(element, loanData);

        if (loanData?.title === element.loanOption) {
          if (bankList.length == 0) {
            setSelectedBankDetail(element);
          }
          element.features = JSON.parse(element.features);
          element.emiCharges = JSON.parse(element.emiCharges);
          element.documentsRequired = JSON.parse(element.documentsRequired);
          element.faqs = JSON.parse(element.faqs);

          bankList.push(element);
        }
      }
      setBankData(bankList);

      console.log(response.data, "bankData");
    } catch (error) {
      console.error("Error fetching bank data:", error);
    }
  };

  useEffect(() => {
    console.log(location.state?.data, "dd");
    getdataSerice();
    getData();
  }, []);

  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-4" id="bank_menu">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    {bankData &&
                      bankData.map((bank, index) => (
                        <li
                          key={index}
                          className={
                            selectedBank?.id === bank.id ? "active" : ""
                          }
                          onClick={() => {
                            setSelectedBankDetail(bank);
                          }}
                        >
                          <a>{bank.bankName}</a>
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="service-sidebar__item service-sidebar__item--contact new-servicesidebar-banner">
                  <div
                    className="service-sidebar__bg"
                    style={{
                      backgroundImage: `url(https://api.dremerz.net/uploads/${loanData?.img})`,
                    }}
                  ></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick Loan Process
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone"
                    >
                      +91 98840 63673
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-lg-8 ${chaning ? "animate" : ""}`}
              id="bank_det"
            >
              <div className="service-details__image">
                <img
                  src={`https://api.dremerz.net/uploads/${selectedBank?.bankLogo}`}
                  alt={selectedBank}
                />
                {/* <div className="service-details__icon">
                  <i className="icon-diamond"></i>
                </div> */}
              </div>
              <div className="service-details__content">
                <h3 className="service-details__title">{loanData?.title}</h3>

                <table className="table table-bordered table-striped table-hover">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Feature</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedBank &&
                      selectedBank.features?.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <b>{item.feature}</b>
                            </td>
                            <td>{item.details}</td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td>
                        <b>Interest Rate</b>
                      </td>
                      <td>{selectedBank?.interestRate}% per annam </td>
                    </tr>
                    <tr>
                      <td>
                        <b>processing Fee</b>
                      </td>
                      <td>{selectedBank?.processingFee}%</td>
                    </tr>
                  </tbody>
                </table>

                <br></br>
                <h3 className="service-details__title">EMI and Charges</h3>

                {selectedBank &&
                  selectedBank.emiCharges?.map((item, index) => {
                    return (
                      <p>
                        <b>{item.title}:</b>
                        {item.details}
                        <br></br>
                      </p>
                    );
                  })}

                <div className="row row-gutter-y-30">
                  <div className="col-md-6">
                    <img
                      src={`https://api.dremerz.net/uploads/${loanData?.img}`}
                      alt=""
                    />
                  </div>

                  <div className="col-md-6">
                    <h3 className="service-details__subtitle">
                      Documents Required
                    </h3>
                    <ul className="list-unstyled ml-0 service-details__list">
                      {selectedBank &&
                        selectedBank.documentsRequired?.map((item, index) => {
                          return (
                            <li>
                              <i className="fa fa-check-circle"></i>{" "}
                              {item.document}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="accrodion-grp service-details__accrodion"
                      data-grp-name="service-details__accrodion-1"
                    >
                      {selectedBank?.faqs.map((item, index) => (
                        <div
                          className={`accrodion wow fadeInUp ${
                            index === clicked && "active"
                          }`}
                          key={index}
                          data-wow-delay="0ms"
                        >
                          <div
                            className="accrodion-title"
                            onClick={() => setClicked(index)}
                          >
                            <h4>{item.question}</h4>
                            <span className="accrodion-icon"></span>
                          </div>
                          {index === clicked && (
                            <div className="accrodion-content">
                              <div className="accrodion-title">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="loan-Calculator pt-120 pb-120">
        <div className="container">
          <div className="loan-calculator__top">
            <div className="row">
              <div className="col-md-6">
                <div className="block-title text-left">
                  <p className="block-title__tagline">Compare Your EMI</p>
                  <h2 className="block-title__title">
                    Comparison With Other Banks
                  </h2>
                </div>
              </div>
              <div className="col-md-6">
                <p className="loan-calculator__top__text">
                  Looking for the best EMI rates for your business loan? Compare
                  our competitive rates with those from other lenders and find
                  out how much more you can save.
                </p>
              </div>
            </div>
          </div>
          <div className="loan-comparison__body" id="compare-emi-1">
            <div className="row">
              <div className="col-lg-6">
                <div className="slider-box">
                  <div className="main-slide">
                    <div className="single-loan-header">
                      <h4>Loan Amount</h4>
                      <input
                        className="form-control input-control"
                        type="text"
                        value={principalrate}
                        onChange={(e) => setPrincipalrate(e.target.value)}
                      />
                    </div>
                    <div className="w-100">
                      <input
                        style={{ maxWidth: "100%" }}
                        className="w-100 slider"
                        type="range"
                        min="1000"
                        max="500000"
                        value={principalrate}
                        onChange={(e) => setPrincipalrate(e.target.value)}
                      />
                    </div>
                    <div className="loan-counter-value">
                      <span>INR {principalrate}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slider-box">
                  <div className="main-slide">
                    <div className="single-loan-header">
                      <h4>Loan Years</h4>
                      <input
                        className="form-control input-control"
                        type="text"
                        value={loanyear}
                        onChange={(e) => setLoanyear(e.target.value)}
                      />
                    </div>
                    <div className="w-100">
                      <input
                        style={{ maxWidth: "100%" }}
                        className="w-100 slider"
                        type="range"
                        min="1"
                        max="24"
                        value={loanyear}
                        onChange={(e) => setLoanyear(e.target.value)}
                      />
                    </div>
                    <div className="loan-counter-value">
                      <span>{loanyear} Years</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead className="thead-dark">
                      <tr>
                        <th>Banks</th>
                        <th>Interest Rate (Lowint-Highint %)</th>
                        {/* <th>Processing Fees</th> */}
                        <th>Monthly EMI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bankData &&
                        bankData.map((item) => (
                          <tr
                            key={item.id}
                            className={
                              item?.bankName.includes(selectedBank?.bankName)
                                ? "bgcolor-orange"
                                : ""
                            }
                          >
                            <td>{item?.bankName}</td>
                            <td>
                              {item?.interestRate} to {item?.processingFee}
                              {/* {item.lowInt}% - {item.highInt}% */}
                            </td>
                            {/* <td>{item?.processingFee}</td> */}
                            {/* <td>{item?.interestRate}</td> */}
                            {/* <td>{}</td> loancal(item.lowInt, item.highInt) */}
                            <td>
                              {loancal(item.interestRate, item.processingFee)}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="lowest-emi-note">
            <span className="indicator"></span>
            <small>This indicates current bank</small>
          </div>
          <div className="terms-conditions">
            <small>**Terms and conditions apply as per banks.</small>
          </div>
        </div>
      </section>
      <div>
        <Emicalculator />
      </div>
    </>
  );
}

export default First;
