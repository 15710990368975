import EmploymentType from "./EmploymentType";
import Pincode from "./PIncode";
import User from "./User";
import Eligibilitycalc from "./Eligibilitycalc";

import { useState } from "react";

const Demo = () => {
  const [step, setstep] = useState(1);
  const [data, setData] = useState({
    employmentType: null,
    pinCode: "",
    fullName: "",
    email: "",
    phone: "",
  });

  return (
    <>
      {step === 1 && (
        <EmploymentType
          data={data}
          setData={setData}
          setstep={setstep}
        ></EmploymentType>
      )}
      {step === 2 && (
        // <Pincode data={data} setData={setData} setstep={setstep}></Pincode>
        <User data={data} setData={setData} setstep={setstep}></User>
      )}
      {step === 3 && (
        // <User data={data} setData={setData} setstep={setstep}></User>
        <Eligibilitycalc
          data={data}
          setData={setData}
          setstep={setstep}
        ></Eligibilitycalc>
      )}
      {/* {step === 4 && (
        <Eligibilitycalc
          data={data}
          setData={setData}
          setstep={setstep}
        ></Eligibilitycalc>
      )} */}
    </>
  );
};
export default Demo;
