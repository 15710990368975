import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Homefaq.css";

import axios from "axios";

const FaqAccordion = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.dremerz.net/api/mybankshoppyhomefaq")
      .then((response) => {
        setFaqs(response.data);
        console.log(response.data, "homefaq");
      })
      .catch((error) => {
        console.error("Error fetching FAQs:", error);
      });
  }, []);

  return (
    <Container className="mt-5">
      <h5 className="mb-4 text-center faq-title">Frequently Asked Questions</h5>
      <Row>
        {faqs.map((faq, index) => (
          <Col xs={12} md={6} key={index} className="mb-4">
            <Accordion defaultActiveKey="0" className="faq-accordion">
              <Accordion.Item eventKey={index.toString()} className="faq-item">
                <Accordion.Header className="faq-header">
                  {faq.question}
                </Accordion.Header>
                <Accordion.Body className="faq-body">
                  {faq.answer}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default FaqAccordion;
