import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { Alert } from "bootstrap";

const Spinner = () => (
  <div className="spinner-container">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const BankForm = () => {
  const [banks, setBanks] = useState([]);
  const [bankData, setBankData] = useState({
    name: "",
    interestRate: "",
    processingFee: "",
    maxTenure: "",
    maxLoanAmount: "",
    category: "",
  });
  const [imageFile, setImageFile] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankData({ ...bankData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleAddBank = async () => {
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("name", bankData.name);
    formData.append("interestRate", bankData.interestRate);
    formData.append("processingFee", bankData.processingFee);
    formData.append("maxTenure", bankData.maxTenure);
    formData.append("maxLoanAmount", bankData.maxLoanAmount);
    formData.append("category", bankData.category);

    try {
      const response = await axios.post(
        "https://api.dremerz.net/api/mybankshoppybankdetails",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert("Loan added successfully!");

        setBanks([...banks, bankData]);

        setBankData({
          name: "",
          interestRate: "",
          processingFee: "",
          maxTenure: "",
          maxLoanAmount: "",
          category: "",
        });
        setImageFile(null);
      } else {
        console.error("Failed to add the bank");
      }
    } catch (error) {
      console.error("Error adding bank:", error);
    }
  };

  const handleDeleteBank = (index) => {
    const updatedBanks = banks.filter((_, i) => i !== index);
    setBanks(updatedBanks);
  };

  return (
    <div className="container my-5">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">
          <label className="form-label">Bank Logo</label>
          <input
            type="file"
            className="form-control"
            onChange={handleImageChange}
            accept="image/*"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Bank Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={bankData.name}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Interest Rate</label>
          <input
            type="text"
            className="form-control"
            name="interestRate"
            value={bankData.interestRate}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Processing Fee</label>
          <input
            type="text"
            className="form-control"
            name="processingFee"
            value={bankData.processingFee}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Max Tenure</label>
          <input
            type="text"
            className="form-control"
            name="maxTenure"
            value={bankData.maxTenure}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Max Loan Amount</label>
          <input
            type="text"
            className="form-control"
            name="maxLoanAmount"
            value={bankData.maxLoanAmount}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Category (LoanType)</label>
          <input
            type="text"
            className="form-control"
            name="category"
            value={bankData.category}
            onChange={handleInputChange}
          />
        </div>

        <button className="btn btn-primary mb-3" onClick={handleAddBank}>
          Add Bank
        </button>
      </form>
    </div>
  );
};

export default BankForm;
