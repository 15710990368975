import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Main";
import Footer from "../components/Footer/Main";
import Home from "../components/Home/Main";
import About from "../components/About/Main";
import Service from "../components/Services/Service/Main";
import ServiceDetails from "../components/Services/ServiceDetails/Main";
import CompareEMI from "../components/Features/CompareEMI/Main";
import HomeLoan from "../components/Features/HomeLoan/Main";
import HomeLoanEligibility from "../components/Features/HomeLoanEligibility/Main";
import LoanEligibility from "../components/Features/LoanEligibility/Main";
import MonthlyLoan from "../components/Features/MonthlyLoan/Main";
import PersonalLoan from "../components/Features/PersonalLoan/Main";
import Contact from "../components/Contact/Main";
import Loan from "../components/Menapplynow/Loan";
import Withcompare from "../components/Mencompare/Withcompare";
import AdminPage from "../components/Admin/AdminPage";
import Aboutusadmin from "../components/Admin/Aboutusadmin";
import Servicesadmin from "../components/Admin/Servicesadmin";
import Contactadmin from "../components/Admin/Contactadmin";
import Servicedetailadmin from "../components/Admin/Servicedetailadmin";
import Banklist from "../components/Mencompare/Banklist";
import Emicalculator from "../components/Emicalculator/Emicalculator";
import Eligibility from "../components/Eligibility/Eligibility";
import Navbaradmin from "../components/NavbarAdmin/Main";
import AddBank from "../components/Admin/Addbank";
import Chatbotadmin from "../components/Admin/Chatbotadmin";

import Userlist from "../components/Admin/Userlist";
import Loanrequestlist from "../components/Admin/Loanrequestlist";
import Spinners from "../components/Spinners/Spinners";
import Demo from "../components/Demo/Demo";
import RupeeSpinner from "../components/Spinners/Spinners";
import { Link } from "react-router-dom";
import Chatbotnew from "../components/Chatboxnew/Chatboxnew";
import Login from "../components/Login/Login";
import Demoadmin from "../components/Chatbot/Demoadmin";
import Userleads from "../components/Userleads/Userleads";
import Register from "../components/Register/Register";
import Forgetpassword from "../components/Forgetpassword/Forgetpassword";
import Test from "../components/Test/Test";
import Privacypolicy from "../components/Privacypolicy/Privacypolicy";

const Routing = () => {
  const [homepage, sethomepage] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/admin" ||
      location.pathname === "/aboutusadmin" ||
      location.pathname === "/serviceadmin" ||
      location.pathname === "/servicedetailadmin" ||
      location.pathname === "/preeligibilityadmin" ||
      location.pathname === "/emicalculatoradmin" ||
      location.pathname === "/contactusadmin" ||
      location.pathname === "/userlistadmin" ||
      location.pathname === "/loanapplicationadmin" ||
      location.pathname === "/addbank" ||
      location.pathname === "/chatbotadmin" ||
      location.pathname === "/leads" ||
      location.pathname === "/adminregister"
    ) {
      sethomepage(false);
    } else {
      sethomepage(true);
    }

    setIsPageLoaded(true);
    const timer = setTimeout(() => {
      setIsPageLoaded(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location]);

  const [isPageLoaded, setIsPageLoaded] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoaded(false);
    }, 2000);
  }, []);
  return (
    <>
      <Chatbotnew />
      {isPageLoaded ? <RupeeSpinner /> : ""}

      {homepage ? <Navbar /> : <Navbaradmin />}
      <Routes>
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/servicedetails" element={<ServiceDetails />} />
        <Route path="/compareEMI" element={<CompareEMI />} />
        <Route path="/homeloan" element={<HomeLoan />} />
        <Route path="/homeloaneligibility" element={<HomeLoanEligibility />} />
        <Route path="/loaneligibility" element={<LoanEligibility />} />
        <Route path="/monthlyloan" element={<MonthlyLoan />} />
        <Route path="/personalloan" element={<PersonalLoan />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/applynow" element={<Loan />} />
        <Route path="/withcompare" element={<Withcompare />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/aboutusadmin" element={<Aboutusadmin />} />
        <Route path="/serviceadmin" element={<Servicesadmin />} />
        <Route path="/contactusadmin" element={<Contactadmin />} />
        <Route path="/servicedetailadmin" element={<Servicedetailadmin />} />
        <Route path="/banklist" element={<Banklist />} />
        <Route path="/chatbotadmin" element={<Chatbotadmin />} />

        <Route path="/emicalculator" element={<Emicalculator />} />
        <Route path="/preeligibility" element={<Eligibility />} />
        <Route path="/addbank" element={<AddBank />} />
        <Route path="/userlistadmin" element={<Userlist />} />
        <Route path="/loanapplicationadmin" element={<Loanrequestlist />} />
        <Route path="/spinner" element={<Spinners />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetpassword" element={<Forgetpassword />} />

        <Route path="/demo" element={<Demo />} />
        <Route path="/leads" element={<Demoadmin />} />
        <Route path="/adminregister" element={<Userleads />} />

        <Route path="/emicalculatoradmin" element={<Emicalculator />} />
        <Route path="/preeligibilityadmin" element={<Eligibility />} />
        <Route path="/spinner" element={<Spinners />} />
        <Route path="/test" element={<Test />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
      </Routes>
      <Footer />
    </>
  );
};

export default Routing;
