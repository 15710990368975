import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Loantable.css";
import * as XLSX from "xlsx";

function LoanTable() {
  const [loanData, setLoanData] = useState([]);

  const handledownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(loanData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "loanData");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const file = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "loanData.xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    alert("download success");
  };

  useEffect(() => {
    fetch("https://api.dremerz.net/api/mybankshoppylistofloanrequest")
      .then((response) => response.json())
      .then((data) => setLoanData(data.reverse()))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-center" style={{ color: "#E63928" }}>
        Loan Applications
      </h2>

      <button
        className="btn mb-4"
        onClick={handledownload}
        style={{ backgroundColor: "#E63928", color: "#ffff" }}
      >
        Download Excel
      </button>

      <div className="table-responsive">
        <table className="table table-bordered table-hover table-striped custom-table">
          <thead className="thead-dark">
            <tr>
              <th>ID</th>
              <th>Amount</th>
              <th>Income</th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact</th>
              <th>CompanyName</th>
              <th>Monthly/Annual</th>
              <th>Residence</th>
              <th>Office</th>
              <th>Pincode</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loanData.map((loan) => (
              <tr key={loan.id}>
                <td>{loan.id}</td>
                <td>{loan.loanamount}</td>
                <td>{loan.income}</td>
                <td>{loan.yourname}</td>
                <td>{loan.youremail}</td>
                <td>{loan.phonenumber}</td>
                <td>{loan.maritalstatus}</td>
                <td>{loan.birthdate}</td>
                <td>{loan.taxpayer}</td>
                <td>{loan.office_location}</td>
                <td>{loan.pincode}</td>
                <td>{loan.employer_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LoanTable;
