import React from "react";
import "./Spinners.css"; // Import custom CSS for the animation

const RupeeSpinner = () => {
  return (
    <div className="spinner-overlay">
      <div className="rupee-container">
        <div className="note note1">₹</div>
        <div className="note note2">₹</div>
        <div className="note note3">₹</div>
      </div>
    </div>
  );
};

export default RupeeSpinner;
