import { useEffect, useState } from "react";
import "./Login.css"; // You can add your custom styles here
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { Spinner } from "../Admin/Servicesadmin";

const LoginForm = () => {
  const [userId, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    getdata();
  };

  const getdata = async () => {
    setLoading(true);
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyuserlistforleads?userid=" +
        userId +
        "&password=" +
        password
    );
    if (response.data.length > 0) {
      Navigate("/demo", { state: { userdetail: response.data[0] } });
    } else {
      alert("Enter Valid Credentials");
    }
    setLoading(false);

    console.log(response.data, "userdata");
  };

  return (
    <div className="wrapper">
      <div className="logo">
        <img
          src="assets/images/favicons/mybankshoppylogotitle.png"
          alt="mybankshoppy logo"
        />
      </div>
      <div className="text-center mt-4 name">MyBankShoppy</div>
      <form className="p-3 mt-3" onSubmit={handleSubmit}>
        <div className="form-field d-flex align-items-center">
          <span className="far fa-user"></span>
          <input
            type="text"
            name="userid"
            id="userId"
            placeholder="user id"
            value={userId}
            autoComplete="off"
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </div>
        <div className="form-field d-flex align-items-center">
          <span className="fas fa-key"></span>
          <input
            type="password"
            name="password"
            id="pwd"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button className="btn mt-3" type="submit">
          {loading ? <Spinner /> : "Login"}
        </button>
      </form>
      <div className="text-center fs-6">
        <a href="/forgetpassword">Forget password?</a>
        <br></br>
        <a href="/register">Register Here</a>
      </div>
    </div>
  );
};

export default LoginForm;
