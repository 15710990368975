import BGS1 from "../../../assets/images/bankloan/icicibusinessloan.avif";
import { Link } from "react-router-dom";
import React, { useState } from "react";

function First() {
  const [selectedBank, setSelectedBank] = useState("ICICI");
  const [clicked, setClicked] = useState(null);

  const bankDetails = {
    ICICI: {
      title: "ICICI Bank Business Loan",
      image: "assets/images/bankloan/icicibusinessloan.avif",
      details: (
        <>
          <p>
            <b>Business Vintage:</b> Minimum 3 years<br></br>
            <b>Program:</b> ITR Surrogate, GST based policy 70% funding.
            <br></br>
            <b>Loan Amount:</b> Loan Amount 10 Lakhs to 100 Lakhs<br></br>
            <b>Interest Rate:</b> 15.00% to 24.00%<br></br>
            <b>Interest Type:</b> Reducing Balance<br></br>
            <b>Tenure Period:</b> 1-4 Years<br></br>
            <b>Processing Fees:</b> Starts from 2.00%+GST<br></br>
            <b>INSURANCE:</b> Starts from 2.00%+GST
          </p>
          <h3 className="service-details__title">EMI and Charges</h3>
          <p>
            <b>EMI Due Date:</b> 5th or 10th of every month<br></br>
            <b>Obligation:</b> Less than 3 EMIs, no obligation<br></br>
            <b>GEO Limit:</b> Based on PIN code mapping<br></br>
            <b>Fees & Charges:</b>
            <br></br>
            <b>Preclosure Fee:</b> Applicable after 3 months, up to 1 year –
            5.00% + GST, Above 1 year – 4.50% + GST<br></br>
            <b>Part Payment:</b> Applicable after 3 months<br></br>
            <b>Age:</b> For Applicant Minimum age 23 – Maximum age 70 (at the
            time of loan maturity)<br></br>
            For Co-Applicant Minimum age 18 – Maximum age 75<br></br>
            <b>Own House:</b> Must have an own house (present or permanent)
            <br></br>
            <b>Co-Applicant:</b> Non-financial co-applicant<br></br>
            <b>CIBIL:</b> Minimum score of 650<br></br>
          </p>
          <div className="row row-gutter-y-30">
            <div className="col-md-6">
              <img src="assets/images/bank/icici.jpeg" alt="" />
            </div>
            <div className="col-md-6">
              <h3 className="service-details__subtitle">Documents Required</h3>
              <ul className="list-unstyled ml-0 service-details__list">
                <li>
                  <i className="fa fa-check-circle"></i> Applicant – Aadhar
                  card, Pan card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Co-applicant – Aadhar
                  card, Pan card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Applicant and
                  co-applicant relationship proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Own house proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Company KYC proof and
                  business vintage proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Bank statement (12
                  months)
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Balance Transfer to
                  Other Bank: Not applicable
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Enquiry Norms: Last 3
                  months 6 unsecured Loan Enquiries allowed
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
      faqs: [
        {
          question:
            "What is the minimum business vintage required to apply for the ICICI Bank Business Loan?",
          answer:
            "To apply for the ICICI Bank Business Loan, your business must have a minimum vintage of 3 years. This means your business should have been operational for at least 3 years.",
        },
        {
          question:
            "What is the loan amount range and interest rate for the ICICI Bank Business Loan?",
          answer:
            "The loan amount for the ICICI Bank Business Loan ranges from ₹10 Lakhs to ₹100 Lakhs. The interest rate varies from 15.00% to 24.00%, depending on your profile and the loan terms.",
        },
        {
          question:
            "What documents are required to apply for the ICICI Bank Business Loan?",
          answer:
            "Proof of relationship between the applicant and co-applicant, proof of own house (present or permanent), company KYC proof, business vintage proof, and a 12-month bank statement.",
        },
        {
          question:
            "Are there any preclosure or part payment fees associated with the loan?",
          answer:
            "Allowed after 3 months, but specific fees or conditions may apply.",
        },
      ],
    },
    HDFC: {
      title: "HDFC Bank Business Loan",
      image: "assets/images/bankloan/hdfcbusinessloan.avif",
      details: (
        <>
          <p>
            <b>Business Vintage:</b> Minimum 3 years<br></br>
            <b>Surrogate Program:</b> ITR Surrogate, GST based policy 70%
            funding & Average Bank Balance should be Proposed EMI & 2 Times,
            Margin Calculation on GST Surrogate<br></br>
            <b>Loan Amount:</b> Loan Amount 10 Lakhs to 100 Lakhs<br></br>
            <b>Interest Rate:</b> 15.00% to 24.00%<br></br>
            <b>Interest Type:</b> Reducing Balance<br></br>
            <b>Tenure Period:</b> 1-4 Years<br></br>
            <b>Processing Fees:</b> Starts from 2.00%+GST<br></br>
            <b>INSURANCE:</b> Starts from 2.00%+GST
          </p>
          <h3 className="service-details__title">EMI and Charges</h3>
          <p>
            <b>EMI Due Date:</b> 5th or 10th of every month<br></br>
            <b>Obligation:</b> Less than 3 EMIs, no obligation<br></br>
            <b>GEO Limit:</b> Based on PIN code mapping<br></br>
            <b>Fees & Charges:</b>
            <br></br>
            <b>Preclosure Fee:</b> Applicable after 6 months, up to 2 years –
            4.00% + GST, After 2 years – 3.00% + GST<br></br>
            <b>Part Payment:</b> Applicable after 12 months<br></br>
            <b>Age:</b> For Applicant Minimum age 23 – Maximum age 70 (at the
            time of loan maturity)<br></br>
            For Co-Applicant Minimum age 18 – Maximum age 75<br></br>
            <b>Own House:</b> Must have an own house (Present or Permanent)
            <br></br>
            <b>Co-Applicant:</b> Non-Financial Co-Applicant<br></br>
            <b>CIBIL:</b> Minimum score of 650<br></br>
          </p>
          <div className="row row-gutter-y-30">
            <div className="col-md-6">
              <img src="assets/images/bank/hdfc.jpeg" alt="" />
            </div>
            <div className="col-md-6">
              <h3 className="service-details__subtitle">Documents Required</h3>
              <ul className="list-unstyled ml-0 service-details__list">
                <li>
                  <i className="fa fa-check-circle"></i> Applicant – Aadhar
                  card, Pan card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Co-applicant – Aadhar
                  card, Pan card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Applicant and
                  co-applicant relationship proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Own house proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Company KYC proof and
                  business vintage proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Bank statement (12
                  months)
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Balance Transfer to
                  Other Bank: Not applicable
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Enquiry Norms: Last 3
                  months 6 unsecured Loan Enquiries allowed
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
      faqs: [
        {
          question:
            "What are the eligibility criteria for the HDFC Bank Business Loan?",
          answer:
            "The eligibility criteria include a minimum business vintage of 3 years, the applicant must be between 23 and 70 years old, and the co-applicant must be between 18 and 75 years old. The applicant must also have an own house (present or permanent) and a minimum CIBIL score of 650.",
        },
        {
          question:
            "What is the range of loan amounts and interest rates available?",
          answer:
            "HDFC Bank offers business loans ranging from ₹10 Lakhs to ₹100 Lakhs. The interest rate varies between 15.00% and 24.00%, depending on the applicant's profile and the specifics of the loan.",
        },
        {
          question:
            "What documents are required to apply for the HDFC Bank Business Loan?",
          answer:
            "Required documents include Aadhar card, Pan card, and photo of both the applicant and co-applicant; proof of relationship between applicant and co-applicant; proof of own house (present or permanent); company KYC proof; business vintage proof; and a 12-month bank statement.",
        },
        {
          question:
            "Are there any fees associated with preclosure or part payment?",
          answer:
            "Yes, preclosure fees apply after 6 months. The fee is 4.00% + GST up to 2 years, and 3.00% + GST after 2 years. Part payments are allowed after 12 months.",
        },
        {
          question:
            "Can the HDFC Bank Business Loan be transferred to other banks or specific banks like AXIS Bank?",
          answer:
            "Balance transfer to other banks, including AXIS Bank, is not applicable for this loan.",
        },
        {
          question: "What are the norms for loan enquiries with HDFC Bank?",
          answer:
            "The enquiry norms allow up to 6 unsecured loan enquiries in the last 3 months.",
        },
      ],
    },
    AXIS: {
      title: "Business Loan",
      image: "assets/images/bankloan/axisbusinessloan.avif",
      details: (
        <>
          <p>
            <b>Business Vintage:</b> Minimum 3 years<br></br>
            <b>Surrogate Program:</b> ITR Surrogate, GST based policy 70%
            funding & Average Bank Balance should be Proposed EMI & 2 Times,
            Margin Calculation on GST Surrogate<br></br>
            <b>Loan Amount:</b> Loan Amount 10 Lakhs to 100 Lakhs<br></br>
            <b>Interest Rate:</b> 15.00% to 24.00%<br></br>
            <b>Interest Type:</b> Reducing Balance<br></br>
            <b>Tenure Period:</b> 1-4 Years<br></br>
            <b>Processing Fees:</b> Starts from 2.00%+GST<br></br>
            <b>INSURANCE:</b> Starts from 2.00%+GST
          </p>
          <h3 className="service-details__title">EMI and Charges</h3>
          <p>
            <b>EMI Due Date:</b> 5th or 10th of every month<br></br>
            <b>Obligation:</b> Less than 3 EMIs, no obligation<br></br>
            <b>GEO Limit:</b> Based on PIN code mapping<br></br>
            <b>Fees & Charges:</b>
            <br></br>
            <b>Preclosure Fee:</b> Applicable after 1 month, up to 1 year –
            5.00% + GST, Above 1 year – 4.50% + GST<br></br>
            <b>Part Payment:</b> Applicable after 1 month<br></br>
            <b>Age:</b> For Applicant Minimum age 23 – Maximum age 70 (at the
            time of loan maturity)<br></br>
            For Co-Applicant Minimum age 18 – Maximum age 75<br></br>
            <b>Own House:</b> Must have an own house (Present or Permanent)
            <br></br>
            <b>Co-Applicant:</b> Non-Financial Co-Applicant<br></br>
            <b>CIBIL:</b> Minimum score of 650<br></br>
          </p>
          <div className="row row-gutter-y-30">
            <div className="col-md-6">
              <img src="assets/images/bank/axis.png" alt="" />
            </div>
            <div className="col-md-6">
              <h3 className="service-details__subtitle">Documents Required</h3>
              <ul className="list-unstyled ml-0 service-details__list">
                <li>
                  <i className="fa fa-check-circle"></i> Applicant – Aadhar
                  card, Pan card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Co-applicant – Aadhar
                  card, Pan card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Applicant and
                  co-applicant relationship proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Own house proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Company KYC proof and
                  business vintage proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Bank statement (12
                  months)
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Balance Transfer to
                  Other Bank: Not applicable
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Balance Transfer to
                  AXIS BANK: Not Applicable
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Enquiry Norms: Last 3
                  months 6 unsecured Loan Enquiries allowed
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
      faqs: [
        {
          question:
            "What is the minimum business vintage required for this loan?",
          answer:
            "The minimum business vintage required is 3 years. This means your business should have been operational for at least 3 years.",
        },
        {
          question:
            "What is the range of loan amounts and interest rates for this business loan?",
          answer:
            "The loan amount ranges from ₹10 Lakhs to ₹100 Lakhs. The interest rates range from 15.00% to 24.00%, depending on the applicant's profile and loan details.",
        },
        {
          question:
            "What are the document requirements for applying for this business loan?",
          answer:
            "Required documents include Aadhar card, Pan card, and photo of both the applicant and co-applicant; proof of relationship between applicant and co-applicant; proof of own house (present or permanent); company KYC proof; business vintage proof; and a 12-month bank statement.",
        },
        {
          question:
            "Are there any fees for preclosure or part payment of this loan?",
          answer:
            "Yes, preclosure fees are applicable after 1 month. The fee is 5.00% + GST up to 1 year and 4.50% + GST after 1 year. Part payments are allowed after 1 month.",
        },
        {
          question:
            "Can this loan be transferred to other banks or specifically to AXIS Bank?",
          answer:
            "No, balance transfers to other banks, including AXIS Bank, are not applicable for this loan.",
        },
        {
          question: "What are the enquiry norms for applying for this loan?",
          answer:
            "The enquiry norms allow up to 6 unsecured loan enquiries in the last 3 months.",
        },
      ],
    },
    IDFC: {
      title: "IDFC FIRST Bank Business Loan",
      image: "assets/images/bankloan/idfcbusinessloan.avif",
      details: (
        <>
          <p>
            <b>Business Vintage:</b> Minimum 3 years<br></br>
            <b>Surrogate Program:</b> ITR Surrogate, GST based policy 70%
            funding & Average Bank Balance should be Proposed EMI & 2 Times,
            Margin Calculation on GST Surrogate<br></br>
            <b>Loan Amount:</b> Loan Amount 10 Lakhs to 100 Lakhs<br></br>
            <b>Interest Rate:</b> 17.00% to 28.00%<br></br>
            <b>Interest Type:</b> Reducing Balance<br></br>
            <b>Tenure Period:</b> 1-4 Years<br></br>
            <b>Processing Fees:</b> Starts from 2.50%+GST<br></br>
            <b>INSURANCE:</b> Starts from 2.50%+GST
          </p>
          <h3 className="service-details__title">EMI and Charges</h3>
          <p>
            <b>EMI Due Date:</b> 3rd of every month<br></br>
            <b>Obligation:</b> Less than 3 EMIs, no obligation<br></br>
            <b>GEO Limit:</b> Based on PIN code mapping<br></br>
            <b>Fees & Charges:</b>
            <br></br>
            <b>Preclosure Fee:</b> Applicable after 6 months, up to 1 year –
            5.00% + GST, Above 1 year – 4.50% + GST<br></br>
            <b>Part Payment:</b> Applicable after 6 months<br></br>
            <b>Age:</b> For Applicant Minimum age 23 – Maximum age 70 (at the
            time of loan maturity)<br></br>
            For Co-Applicant Minimum age 18 – Maximum age 75<br></br>
            <b>Own House:</b> Must have an own house (Present or Permanent)
            <br></br>
            <b>Co-Applicant:</b> Non-Financial Co-Applicant<br></br>
            <b>CIBIL:</b> Minimum score of 650<br></br>
          </p>
          <div className="row row-gutter-y-30">
            <div className="col-md-6">
              <img src="assets/images/bank/idfc.png" alt="" />
            </div>
            <div className="col-md-6">
              <h3 className="service-details__subtitle">Documents Required</h3>
              <ul className="list-unstyled ml-0 service-details__list">
                <li>
                  <i className="fa fa-check-circle"></i> Applicant – Aadhar
                  card, Pan card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Co-applicant – Aadhar
                  card, Pan card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Applicant and
                  co-applicant relationship proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Own house proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Company KYC proof and
                  business vintage proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Bank statement (12
                  months)
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Balance Transfer to
                  Other Bank: Not applicable
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Enquiry Norms: Last 3
                  months 9 unsecured Loan Enquiries allowed
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
      faqs: [
        {
          question:
            "What is the minimum business vintage required for the IDFC FIRST Bank Business Loan?",
          answer:
            "The minimum business vintage required is 3 years. This means your business should have been operational for at least 3 years.",
        },
        {
          question:
            "What loan amounts and interest rates does IDFC FIRST Bank offer?",
          answer:
            "IDFC FIRST Bank offers business loans ranging from ₹10 Lakhs to ₹100 Lakhs. The interest rates vary between 17.00% and 28.00%, based on the applicant's profile and the specifics of the loan.",
        },
        {
          question:
            "What documents are required for applying for the IDFC FIRST Bank Business Loan?",
          answer:
            "Documents required include Aadhar card, Pan card, and photo of both the applicant and co-applicant; proof of relationship between applicant and co-applicant; proof of own house (present or permanent); company KYC proof; business vintage proof; and a 12-month bank statement.",
        },
        {
          question:
            "Are there any preclosure or part payment fees associated with this loan?",
          answer:
            "Yes, preclosure fees are applicable after 6 months. The fee is 5.00% + GST up to 1 year and 4.50% + GST after 1 year. Part payments are allowed after 6 months.",
        },
        {
          question:
            "Can the IDFC FIRST Bank Business Loan be transferred to other banks or specifically to AXIS Bank?",
          answer:
            "No, balance transfers to other banks, including AXIS Bank, are not applicable for this loan.",
        },
        {
          question:
            "What are the enquiry norms for the IDFC FIRST Bank Business Loan?",
          answer:
            "The enquiry norms allow up to 9 unsecured loan enquiries in the last 3 months.",
        },
      ],
    },
    NBFC: {
      title: "NBFC Business Loan",
      image: "assets/images/bankloan/nbfcbusinessloan.avif",
      details: (
        <>
          <p>
            <b>Business Vintage:</b> Minimum 2 years<br></br>
            <b>Surrogate Program:</b> GST-based policy with up to 70% funding;
            average bank balance should cover Proposed EMI & 2x margin based on
            GST surrogate<br></br>
            <b>Loan Amount:</b> ₹5 Lakhs to ₹200 Lakhs<br></br>
            <b>Interest Rate:</b> 16.00% to 26.00%<br></br>
            <b>Interest Type:</b> Reducing Balance<br></br>
            <b>Tenure Period:</b> 1-5 Years<br></br>
            <b>Processing Fees:</b> Starts from 2.00%+GST<br></br>
            <b>Insurance:</b> Starts from 1.50%+GST
          </p>
          <h3 className="service-details__title">EMI and Charges</h3>
          <p>
            <b>EMI Due Date:</b> 7th or 12th of every month<br></br>
            <b>Obligation:</b> Less than 3 EMIs, no obligation<br></br>
            <b>GEO Limit:</b> Based on PIN code mapping<br></br>
            <b>Fees & Charges:</b>
            <br></br>
            <b>Preclosure Fee:</b> Applicable after 9 months, up to 1 year –
            4.50% + GST, Above 1 year – 3.50% + GST<br></br>
            <b>Part Payment:</b> Applicable after 6 months<br></br>
            <b>Age:</b> For Applicant Minimum age 24 – Maximum age 65 (at the
            time of loan maturity)<br></br>
            For Co-Applicant Minimum age 21 – Maximum age 70<br></br>
            <b>Own House:</b> Must have an own house (Present or Permanent)
            <br></br>
            <b>Co-Applicant:</b> Non-Financial Co-Applicant<br></br>
            <b>CIBIL:</b> Minimum score of 680<br></br>
          </p>
          <div className="row row-gutter-y-30">
            <div className="col-md-6">
              <img src="assets/images/bank/nbfc.png" alt="" />
            </div>
            <div className="col-md-6">
              <h3 className="service-details__subtitle">Documents Required</h3>
              <ul className="list-unstyled ml-0 service-details__list">
                <li>
                  <i className="fa fa-check-circle"></i> Applicant – Aadhar
                  card, PAN card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Co-applicant – Aadhar
                  card, PAN card, Photo
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Applicant and
                  co-applicant relationship proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Own house proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Company KYC proof and
                  business vintage proof
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Bank statement (12
                  months)
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Balance Transfer: Not
                  applicable
                </li>
                <li>
                  <i className="fa fa-check-circle"></i> Enquiry Norms: Last 3
                  months, 6 unsecured loan enquiries allowed
                </li>
              </ul>
            </div>
          </div>
        </>
      ),
      faqs: [
        {
          question:
            "What is the minimum business vintage required for an NBFC Business Loan?",
          answer:
            "The minimum business vintage required is 2 years. Your business should have been operational for at least 2 years.",
        },
        {
          question: "What loan amounts and interest rates does the NBFC offer?",
          answer:
            "NBFC offers business loans ranging from ₹5 Lakhs to ₹200 Lakhs. The interest rates vary between 16.00% and 26.00%, depending on your profile and loan specifics.",
        },
        {
          question:
            "What documents are required for applying for an NBFC Business Loan?",
          answer:
            "Required documents include Aadhar card, PAN card, and photo of both the applicant and co-applicant; relationship proof between applicant and co-applicant; own house proof; company KYC proof; business vintage proof; and a 12-month bank statement.",
        },
        {
          question: "Are there any preclosure or part payment fees?",
          answer:
            "Yes, preclosure is allowed after 9 months with fees of 4.50% + GST up to 1 year and 3.50% + GST after 1 year. Part payments are allowed after 6 months.",
        },
        {
          question: "Can the NBFC Business Loan be transferred to other banks?",
          answer:
            "No, balance transfers to other banks are not applicable for this loan.",
        },
        {
          question: "What are the enquiry norms for the NBFC Business Loan?",
          answer:
            "The enquiry norms allow up to 6 unsecured loan enquiries in the last 3 months.",
        },
      ],
    },
  };

  return (
    <>
      <section className="service-details pt-120 pb-120">
        <div className="container">
          <div className="row row-gutter-y-30">
            <div className="col-lg-4">
              <div className="service-sidebar">
                <div className="service-sidebar__item service-sidebar__item--menu">
                  <ul className="service-sidebar__menu">
                    {Object.keys(bankDetails).map((bank, index) => (
                      <li
                        key={index}
                        className={selectedBank === bank ? "active" : ""}
                        onClick={() => setSelectedBank(bank)}
                      >
                        <a href="#">{bank}</a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="service-sidebar__item service-sidebar__item--contact">
                  <div
                    className="service-sidebar__bg"
                    style={{ backgroundImage: `url(${BGS1})` }}
                  ></div>
                  <div className="service-sidebar__contact">
                    <div className="service-sidebar__icon">
                      <i className="icon-phone-ringing"></i>
                    </div>
                    <h3 className="service-sidebar__title">
                      Quick Loan Process
                    </h3>
                    <hr className="service-sidebar__separator" />
                    <p className="service-sidebar__tagline">
                      Talk to an expert
                    </p>
                    <Link
                      to="tel:+1-(246)333-0089"
                      className="service-sidebar__phone"
                    >
                      +91 98840 63673
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="service-details__image">
                <img src={bankDetails[selectedBank].image} alt={selectedBank} />
                <div className="service-details__icon">
                  <i className="icon-diamond"></i>
                </div>
              </div>
              <div className="service-details__content">
                <h3 className="service-details__title">
                  {bankDetails[selectedBank].title}
                </h3>
                {bankDetails[selectedBank].details}

                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="accrodion-grp service-details__accrodion"
                      data-grp-name="service-details__accrodion-1"
                    >
                      {bankDetails[selectedBank].faqs.map((item, index) => (
                        <div
                          className={`accrodion wow fadeInUp ${
                            index === clicked && "active"
                          }`}
                          key={index}
                          data-wow-delay="0ms"
                        >
                          <div
                            className="accrodion-title"
                            onClick={() => setClicked(index)}
                          >
                            <h4>{item.question}</h4>
                            <span className="accrodion-icon"></span>
                          </div>
                          {index === clicked && (
                            <div className="accrodion-content">
                              <div className="accrodion-title">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default First;
