import React, { useEffect, useState } from "react";
import axios from "axios";
import Testimonials from "../Menhome/Testimonials";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { Spinner } from "./Servicesadmin";
function ImageUploadForm() {
  // const [images, setImages] = useState([]);
  const [newImage, setNewImage] = useState([]);
  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({});
  const [deviceType, setDeviceType] = useState("desktop");

  const [additionalImage, setAdditionalImage] = useState(null);
  const [tiledata, setTileData] = useState([]);
  const [tiles, setTiles] = useState([]);

  const [selectedTile, setSelectedTile] = useState(null);

  const [videodata, setVideoData] = useState({});
  const [counterData, setcounterData] = useState({});
  const [benefitsData, setBenefitsData] = useState({});
  const [testimonialData, setTestimonialData] = useState({});
  const [testimonials, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cimage, setCimage] = useState(null);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [faqs, setFaqs] = useState([]);

  const [faqGroups, setFaqGroups] = useState([]);

  const handlefaqsubmit = (e) => {
    e.preventDefault();
    var succe = false;
    faqs.map((item, index) => {
      console.log(item);
      const formData = new FormData();
      formData.append("question", item.question);
      formData.append("answer", item.answer);

      setLoading(true);

      if ("id" in item) {
        axios
          .put(
            "https://api.dremerz.net/api/mybankshoppyhomefaq/" + item.id + "/",
            formData
          )
          .then((response) => {
            console.log("Faq updated successfully:", response.data);
            succe = true;
            if (succe && index == faqs.length - 1) {
              console.log(succe);
              if (succe) {
                alert("FAQ updated successfully");
                setLoading(false);
              }
            }
          })
          .catch((error) => {
            succe = false;
            console.error("Error adding FAQ:", error);
            setLoading(false);
          });
      } else {
        axios
          .post("https://api.dremerz.net/api/mybankshoppyhomefaq", formData)
          .then((response) => {
            console.log("Faq added successfully:", response.data);
            succe = true;
            if (succe && index == faqs.length - 1) {
              console.log(succe);
              if (succe) {
                alert("FAQ added successfully");
                setLoading(false);
              }
            }
          })
          .catch((error) => {
            succe = false;
            console.error("Error adding FAQ:", error);
            setLoading(false);
          });
      }
    });
  };
  const handlefaqChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFaqs = [...faqs];
    updatedFaqs[index][name] = value;
    setFaqs(updatedFaqs);
  };
  const handlefaqDeleteRow = (id) => {
    const confirm = window.confirm("Are You Sure Want To Delete this FAQ?");
    if (!confirm) {
      return;
    } else {
      axios
        .delete("https://api.dremerz.net/api/mybankshoppyhomefaq/" + id + "/")
        .then((response) => {
          console.log("Faq deleted successfully:", response.data);

          alert("FAQ deleted successfully");
          setLoading(false);
          getfaqdata();
        })
        .catch((error) => {
          console.error("Error delete FAQ:", error);
          setLoading(false);
        });
    }
  };

  const handlefaqAddRow = () => {
    setFaqs([...faqs, { question: "", answer: "" }]);
  };

  const handleDeviceChange = (e) => {
    setDeviceType(e.target.value);
  };

  const uploadFile = (event) => {
    event.preventDefault();
    try {
      const handleuploadfile = new FormData();
      handleuploadfile.append("image", cimage);
      handleuploadfile.append("deviceType", deviceType);
      setLoading(true);
      axios
        .post(
          "https://api.dremerz.net/api/mybankshoppyhomeslider",
          handleuploadfile
        )
        .then((respo) => {
          setLoading(false);

          if (respo.status === 200) {
            document.getElementById("image_banner").value = null;
            alert("Image updated successfully");
            getHomeImg();
          } else {
            console.log("Failed to update Image");
          }
        });
    } catch (error) {
      setLoading(false);

      console.error("Error updating Image:", error);
    }
  };

  const handleFileChange = (event) => {
    console.log(event.target.files[0]);
    setCimage(event.target.files[0]);
  };

  const handleRemoveImage = (index, id) => {
    setImages(images.filter((_, i) => i !== index));

    axios
      .delete("https://api.dremerz.net/api/mybankshoppyhomeslider/" + id + "/")
      .then((respo) => {
        if (respo.status === 200) {
          alert("Image Deleted successfully");
          getHomeImg();
        } else {
          console.log("Failed to Deleted Image");
        }
      });
  };

  // Handle additional image change
  const handleAdditionalImageChange = (e) => {
    setAdditionalImage(e.target.files[0]);
  };

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  // Handle form data submission
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formDataToSend = new FormData();

      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key]);
      });

      console.log(additionalImage);

      if (additionalImage) {
        formDataToSend.append("additionalImage", additionalImage);
      }

      const response = await axios.put(
        "https://api.dremerz.net/api/mybankshoppyaboutcompanysection/1/",
        formDataToSend
      );

      if (response.status === 200) {
        alert("about company section updated successfully");
      } else {
        alert("Failed to update company section");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setLoading(false);
  };
  //handle tile change
  const handleTileChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTiles = [...tiles];
    updatedTiles[index] = { ...updatedTiles[index], [name]: value };
    setTiles(updatedTiles);
  };
  // handle tile submit
  const handleAllTilesSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await Promise.all(
        tiles.map((tile) =>
          axios.put(
            `https://api.dremerz.net/api/mybankshoppytilesection/${tile.id}/`,
            tile
          )
        )
      );
      console.log("Tiles updated successfully");
      alert("Tiles updated successfully");
    } catch (error) {
      console.error("Error updating tiles:", error);
    }
    setLoading(false);
  };
  // handle youtube section change
  const handleyoutubeChange = (e) => {
    const { name, value } = e.target;
    setVideoData((prevData) => ({ ...prevData, [name]: value }));
  };
  // handle youtube submit
  const handleyoutubesubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const youtubedata = new FormData();
      Object.keys(videodata).forEach((key) => {
        youtubedata.append(key, videodata[key]);
      });

      const response = await axios.put(
        "https://api.dremerz.net/api/mybankshoppytyoutubevideosection/1/",
        youtubedata
      );

      if (response.status === 200) {
        console.log("YouTube section updated successfully");
        alert("YouTube section updated successfully");
      } else {
        console.log("Failed to update YouTube section");
      }
    } catch (error) {
      console.error("Error updating YouTube section:", error);
    }
    setLoading(false);
  };
  //handle testimonial change
  const handletestimonialchange = (e) => {
    const { name, value } = e.target;
    setTestimonialData((prevData) => ({ ...prevData, [name]: value }));
  };
  //handle testimonial change
  const handletestimonialReviewchange = (e, i) => {
    const { name, value } = e.target;
    const updatedTestimonials = [...testimonials];

    // Update the  field
    updatedTestimonials[i] = {
      ...updatedTestimonials[i],
      [name]: value,
    };

    setTestimonials(updatedTestimonials);

    setTestimonialData((prevData) => ({
      ...prevData,
      reviews: JSON.stringify(updatedTestimonials),
    }));
  };
  //handle testimonial submit
  const handletestimonialsubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const newtestmonialdata = new FormData();
      Object.keys(testimonialData).forEach((key) => {
        newtestmonialdata.append(key, testimonialData[key]);
      });

      const response = await axios.put(
        "https://api.dremerz.net/api/mybankshoppytestimonialreviews/1/",
        newtestmonialdata
      );

      if (response.status === 200) {
        console.log("Testimonial section updated successfully");
        alert("Testimonial section updated successfully");
      } else {
        console.log("Failed to update Testimonial section");
      }
    } catch (error) {
      console.error("Error updating Testimonial section:", error);
    }
    setLoading(false);
  };
  //handle counter change
  const handlecounterChange = (e) => {
    const { name, value } = e.target;
    setcounterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //handle counter submit
  const handlecountersubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const handlecountersubmit = new FormData();
      Object.keys(counterData).forEach((key) => {
        handlecountersubmit.append(key, counterData[key]);
      });

      const response = await axios.put(
        "https://api.dremerz.net/api/mybankshoppycountersection/1/",
        handlecountersubmit
      );

      if (response.status === 200) {
        alert("counter section updated successfully");
      } else {
        console.log("Failed to update counter section");
      }
    } catch (error) {
      console.error("Error updating counter section:", error);
    }
    setLoading(false);
  };
  //handle benefits change
  const handlebenefitsChange = (e) => {
    const { name, value } = e.target;
    setBenefitsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //handle benefits submiit
  const handlebenefitssubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const handlebenefitssubmit = new FormData();
      Object.keys(benefitsData).forEach((key) => {
        handlebenefitssubmit.append(key, benefitsData[key]);
      });

      const response = await axios.put(
        "https://api.dremerz.net/api/mybankshoppybenefitssection/1/",
        handlebenefitssubmit
      );

      if (response.status === 200) {
        alert("benefits section updated successfully");
      } else {
        console.log("Failed to update YouTube section");
      }
    } catch (error) {
      console.error("Error updating YouTube section:", error);
    }
    setLoading(false);
  };
  const getHomeImg = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyhomeslider"
    );
    setImages(response.data);
    console.log(response.data);
  };
  const getAbout = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyaboutcompanysection"
      );
      setFormData(response.data[0]);
      console.log(response.data, "aboutus");
    } catch (ex) {
      console.log(ex);
    }
  };
  const getTileData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppytilesection"
      );
      setTiles(response.data);
      // Initialize the selectedTile with the first tile if available
      if (response.data.length > 0) {
        setSelectedTile(response.data[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getvideolink = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppytyoutubevideosection"
      );
      console.log(response.data);

      setVideoData(response.data[0]);
    } catch (error) {
      console.error(error);
    }
  };
  const gettestimonial = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppytestimonialreviews"
      );
      console.log(response.data, "test");
      setTestimonialData(response.data[0]);
      setTestimonials(JSON.parse(response.data[0].reviews));
    } catch (error) {}
  };
  const getcounter = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppycountersection"
      );
      console.log(response.data);
      setcounterData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const getbenefits = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppybenefitssection"
      );
      setBenefitsData(response.data[0]);
      console.log(response.data, "benefits");
    } catch (error) {
      console.log(error);
    }
  };
  const getfaqdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyhomefaq"
      );
      console.log(response.data, "homefaq");
      setFaqs(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getHomeImg();
    getAbout();
    getTileData();
    getvideolink();
    gettestimonial();
    getcounter();
    getbenefits();
    getfaqdata();
  }, []);

  return (
    <>
      {/* banner section */}

      <div className="container mt-5">
        <h2 style={{ textAlign: "center" }}>Home Banner Images</h2>
        <div>
          <div className="container mt-3">
            <label htmlFor="deviceType" className="form-label">
              Select Device Type:
            </label>
            <select
              id="deviceType"
              className="form-control"
              value={deviceType}
              onChange={handleDeviceChange}
            >
              <option value="desktop">Desktop</option>
              <option value="mobile">Mobile</option>
            </select>
          </div>
        </div>

        <Container>
          <Form onSubmit={uploadFile}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select Image(s)</Form.Label>
              <Form.Control
                type="file"
                id="image_banner"
                onChange={handleFileChange}
                accept="image/*"
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Button type="submit" className="btn btn-primary">
                {loading ? <Spinner /> : "Upload"}
              </Button>
            </Form.Group>
          </Form>

          <Row>
            {images
              .filter(
                (image) =>
                  image.deviceType === deviceType || image.deviceType === ""
              )

              .map((image, index) => (
                <Col md={3} sm={6} key={index} className="mb-3">
                  <div className="position-relative">
                    <img
                      src={`https://api.dremerz.net/uploads/${image.image}`}
                      alt={`Preview ${index}`}
                      className="img-fluid"
                    />
                    <Button
                      variant="danger"
                      size="sm"
                      className="position-absolute top-0 end-0 m-2"
                      onClick={() => handleRemoveImage(index, image.id)}
                    >
                      &times;
                    </Button>
                  </div>
                </Col>
              ))}
          </Row>
          <hr style={{ color: "#E63928", border: "3px solid" }}></hr>
        </Container>
      </div>

      {/* about company section */}
      <div className="container mt-5">
        <h2 style={{ textAlign: "center" }}>About Company Section</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="titlecompany">Title</label>
            <input
              type="text"
              id="titlecompany"
              name="titlecompany"
              value={formData?.titlecompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="headingcompany">Subject</label>
            <input
              type="text"
              id="headingcompany"
              name="headingcompany"
              value={formData?.headingcompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="pinchcompany">Tagline</label>
            <input
              type="text"
              id="pinchcompany"
              name="pinchcompany"
              value={formData?.pinchcompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="pinchdetailcompany">Tagline Description</label>
            <input
              type="text"
              id="pinchdetailcompany"
              name="pinchdetailcompany"
              value={formData?.pinchdetailcompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lefttitlecompany">Loan type 1-Title</label>
            <input
              type="text"
              id="lefttitlecompany"
              name="lefttitlecompany"
              value={formData?.lefttitlecompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="leftdetailcompany">Loan type 1-Description </label>
            <input
              type="text"
              id="leftdetailcompany"
              name="leftdetailcompany"
              value={formData?.leftdetailcompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="righttitlecompany">Loan Type 2-title</label>
            <input
              type="text"
              id="righttitlecompany"
              name="righttitlecompany"
              value={formData?.righttitlecompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="rightdetailcompany">Loan Type 2-Description</label>
            <input
              type="text"
              id="rightdetailcompany"
              name="rightdetailcompany"
              value={formData?.rightdetailcompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="buttontitlecompany">Button Link</label>
            <input
              type="text"
              id="buttontitlecompany"
              name="buttontitlecompany"
              value={formData?.buttontitlecompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="yearscompany">Years label count</label>
            <input
              type="text"
              id="yearscompany"
              name="yearscompany"
              value={formData?.yearscompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="yearssymbolcompany">Years Symbol label text</label>
            <input
              type="text"
              id="yearssymbolcompany"
              name="yearssymbolcompany"
              value={formData?.yearssymbolcompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="yearstextcompany">Years Text</label>
            <input
              type="text"
              id="yearstextcompany"
              name="yearstextcompany"
              value={formData?.yearstextcompany}
              onChange={handleChange}
              className="form-control"
            />
          </div>
          <div className="form-group mt-3">
            <label htmlFor="additionalImage">Select Additional Image</label>
            <input
              type="file"
              id="additionalImage"
              accept="image/*"
              className="form-control"
              onChange={handleAdditionalImageChange}
            />
            {formData.additionalImage && (
              <div className="mt-3">
                <h5>about company image</h5>
                <img
                  src={`https://api.dremerz.net/uploads/${formData?.additionalImage}`}
                  alt="Selected"
                  style={{ maxWidth: "100%", maxHeight: "100px" }}
                />
              </div>
            )}
          </div>
          <button type="submit" className="btn btn-primary mt-3">
            {loading ? <Spinner /> : "Update"}
          </button>
          <hr style={{ color: "#E63928", border: "3px solid" }}></hr>
        </form>
      </div>
      {/* tiles section */}
      <div className="container mt-5">
        <h2 style={{ textAlign: "center" }}>Tile Management</h2>
        <form onSubmit={handleAllTilesSubmit}>
          {tiles.map((tile, index) => (
            <div className="card mb-3" key={tile.id}>
              <div className="card-body">
                <h5 className="card-title">Edit Tile {index + 1}</h5>
                <div className="form-group">
                  <label htmlFor={`tileTitle-${index}`}>Tile Title</label>
                  <input
                    type="text"
                    name="tiletitle"
                    className="form-control"
                    id={`tileTitle-${index}`}
                    placeholder="Enter tile title"
                    value={tile.tiletitle}
                    onChange={(e) => handleTileChange(index, e)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor={`tileDescription-${index}`}>
                    Tile Description
                  </label>
                  <textarea
                    className="form-control"
                    id={`tileDescription-${index}`}
                    name="tiledescription"
                    rows="3"
                    placeholder="Enter tile description"
                    value={tile.tiledescription}
                    onChange={(e) => handleTileChange(index, e)}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor={`tileLink-${index}`}>Tile Link</label>
                  <input
                    type="text"
                    className="form-control"
                    id={`tileLink-${index}`}
                    name="tilelink"
                    placeholder="Enter tile link"
                    value={tile.tilelink}
                    onChange={(e) => handleTileChange(index, e)}
                  />
                </div>
              </div>
            </div>
          ))}
          <button type="submit" className="btn btn-primary mt-3">
            {loading ? <Spinner /> : "Update Tile"}
          </button>
        </form>
        <hr style={{ color: "#E63928", border: "3px solid" }}></hr>
      </div>

      {/* youtube section */}
      <div className="container mt-5">
        <h2 style={{ textAlign: "center" }}>YouTube Management</h2>
        <form onSubmit={handleyoutubesubmit}>
          <div className="form-group">
            <label htmlFor="videoTitle">Youtube Video Link</label>
            <input
              type="text"
              name="youtubevideolink"
              className="form-control"
              id="videoTitle"
              value={videodata.youtubevideolink}
              onChange={handleyoutubeChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="videoDescription">Video Description</label>
            <textarea
              className="form-control"
              id="videoDescription"
              name="videodescription"
              rows="3"
              value={videodata.videodescription}
              onChange={handleyoutubeChange}
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="videoUrl">Label 1</label>
            <input
              type="text"
              className="form-control"
              id="videoUrl"
              name="youtubelabel1"
              value={videodata.youtubelabel1}
              onChange={handleyoutubeChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="thumbnailUrl">Label 2</label>
            <input
              type="text"
              className="form-control"
              id="thumbnailUrl"
              name="youtubelabel2"
              value={videodata.youtubelabel2}
              onChange={handleyoutubeChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="videoCategory">Label 3</label>
            <input
              type="text"
              className="form-control"
              id="videoCategory"
              name="youtubelabel3"
              value={videodata.youtubelabel3}
              onChange={handleyoutubeChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="videoTags">Label 4</label>
            <input
              type="text"
              className="form-control"
              id="videoTags"
              name="youtubelabel4"
              value={videodata.youtubelabel4}
              onChange={handleyoutubeChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="publishDate">Label 5</label>
            <input
              type="text"
              className="form-control"
              id="publishDate"
              name="youtubelabel5"
              value={videodata.youtubelabel5}
              onChange={handleyoutubeChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="videoOrder">Label 6</label>
            <input
              type="text"
              className="form-control"
              id="videoOrder"
              name="youtubelabel6"
              value={videodata.youtubelabel6}
              onChange={handleyoutubeChange}
            />
          </div>
          <br></br>
          <button type="submit" className="btn btn-primary">
            {loading ? <Spinner /> : "Update Youtube"}
          </button>
          <hr style={{ color: "#E63928", border: "3px solid" }}></hr>
        </form>
      </div>
      {/* testimonial  section */}
      <div className="container mt-5">
        <form onSubmit={handletestimonialsubmit}>
          <h2 style={{ textAlign: "center" }}> Testimonials Section</h2>
          <div className="form-group">
            <label htmlFor="title">Testimonial Title</label>
            <input
              type="text"
              id="title"
              className="form-control"
              name="title"
              onChange={handletestimonialchange}
              value={testimonialData.title}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Testimonial Heading</label>
            <input
              type="text"
              id="description"
              className="form-control"
              name="description"
              onChange={handletestimonialchange}
              value={testimonialData.description}
            />
          </div>
          <br></br>
          {testimonials.map((values, i) => (
            <>
              <div className="testimonial-group">
                <h4>Review Name</h4>
                <div className="form-group">
                  <label>Reviewer Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={(e) => {
                      handletestimonialReviewchange(e, i);
                    }}
                    value={values.name}
                  />
                </div>
                <div className="form-group">
                  <label>Review</label>
                  <textarea
                    className="form-control"
                    name="review"
                    rows="3"
                    onChange={(e) => {
                      handletestimonialReviewchange(e, i);
                    }}
                    value={values.review}
                  ></textarea>
                </div>
              </div>
            </>
          ))}

          <button type="submit" className="btn btn-primary">
            {loading ? <Spinner /> : "Update"}
          </button>
        </form>
        <hr style={{ color: "#E63928", border: "3px solid" }}></hr>
      </div>
      <br></br>
      {/* counter section */}
      <div className="container">
        <h2 style={{ textAlign: "center" }}>Counter Section</h2>
        <form onSubmit={handlecountersubmit}>
          <div className="row">
            {/* First Row */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input1">counter 1st count</label>
                <input
                  type="text"
                  id="input1"
                  name="input1"
                  className="form-control"
                  value={counterData.input1}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input2">counter 1st suffix</label>
                <input
                  type="text"
                  id="input2"
                  name="input2"
                  className="form-control"
                  value={counterData.input2}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input3">counter 1st title</label>
                <input
                  type="text"
                  id="input3"
                  name="input3"
                  className="form-control"
                  value={counterData.input3}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* Second Row */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input4">counter 2nd count</label>
                <input
                  type="text"
                  id="input4"
                  name="input4"
                  className="form-control"
                  value={counterData.input4}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input5">counter 2nd suffix</label>
                <input
                  type="text"
                  id="input5"
                  name="input5"
                  className="form-control"
                  value={counterData.input5}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input6">counter 2nd title</label>
                <input
                  type="text"
                  id="input6"
                  name="input6"
                  className="form-control"
                  value={counterData.input6}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* Third Row */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input7">counter 3rd count</label>
                <input
                  type="text"
                  id="input7"
                  name="input7"
                  className="form-control"
                  value={counterData.input7}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input8">counter 3rd suffix</label>
                <input
                  type="text"
                  id="input8"
                  name="input8"
                  className="form-control"
                  value={counterData.input8}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input9">counter 3rd title</label>
                <input
                  type="text"
                  id="input9"
                  name="input9"
                  className="form-control"
                  value={counterData.input9}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* Fourth Row */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input10">counter 4th title</label>
                <input
                  type="text"
                  id="input10"
                  name="input10"
                  className="form-control"
                  value={counterData.input10}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
            {/* <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input11">counter 4 suffix</label>
                <input
                  type="text"
                  id="input11"
                  name="input11"
                  className="form-control"
                  value={counterData.input11}
                  onChange={handlecounterChange}
                />
              </div>
            </div> */}
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="form-group">
                <label htmlFor="input12">counter 4th title</label>
                <input
                  type="text"
                  id="input12"
                  name="input12"
                  className="form-control"
                  value={counterData.input12}
                  onChange={handlecounterChange}
                />
              </div>
            </div>
          </div>
          <br></br>
          <button type="submit" className="btn btn-primary">
            {loading ? <Spinner /> : "Update Counters"}
          </button>
        </form>
        <hr style={{ color: "#E63928", border: "3px solid" }}></hr>
      </div>
      <br></br>
      {/* benefits section */}

      <div className="container">
        <h2 style={{ textAlign: "center" }}>Our Benefits Section</h2>
        <form onSubmit={handlebenefitssubmit}>
          <div className="row">
            {/* First Row */}
            <div className="form-group">
              <label htmlFor="image1">Upload Image </label>
              <input
                type="file"
                id="image1"
                name="image1"
                className="form-control"
                onChange={handlebenefitsChange}
              />
            </div>
            <div>
              {/* {benefitsData.map((values) => ( */}
              <img
                src={`https://api.dremerz.net/uploads/${benefitsData.input1}`}
                style={{ maxWidth: "200px", maxHeight: "200px" }}
              />
              {/* ))} */}
            </div>
            <div className="form-group">
              <label htmlFor="input2">title</label>
              <input
                type="text"
                id="input2"
                name="input2"
                className="form-control"
                value={benefitsData.input2}
                onChange={handlebenefitsChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input3">heading</label>
              <input
                type="text"
                id="input3"
                name="input3"
                className="form-control"
                value={benefitsData.input3}
                onChange={handlebenefitsChange}
              />
            </div>
          </div>
          <div className="row">
            {/* Second Row */}
            <div className="form-group">
              <label htmlFor="input4">description</label>
              <input
                type="text"
                id="input4"
                name="input4"
                className="form-control"
                value={benefitsData.input4}
                onChange={handlebenefitsChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input5">bank heading</label>
              <input
                type="text"
                id="input5"
                name="input5"
                className="form-control"
                value={benefitsData.input5}
                onChange={handlebenefitsChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input6">bank description</label>
              <input
                type="text"
                id="input6"
                name="input6"
                className="form-control"
                value={benefitsData.input6}
                onChange={handlebenefitsChange}
              />
            </div>
          </div>
          <div className="row">
            {/* Third Row */}
            <div className="form-group">
              <label htmlFor="input7">limit heading</label>
              <input
                type="text"
                id="input7"
                name="input7"
                className="form-control"
                value={benefitsData.input7}
                onChange={handlebenefitsChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input8">limit description</label>
              <input
                type="text"
                id="input8"
                name="input8"
                className="form-control"
                value={benefitsData.input8}
                onChange={handlebenefitsChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input9">steps heading</label>
              <input
                type="text"
                id="input9"
                name="input9"
                className="form-control"
                value={benefitsData.input9}
                onChange={handlebenefitsChange}
              />
            </div>
          </div>
          <div className="row">
            {/* Fourth Row */}
            <div className="form-group">
              <label htmlFor="input10">steps description</label>
              <input
                type="text"
                id="input10"
                name="input10"
                className="form-control"
                value={benefitsData.input10}
                onChange={handlebenefitsChange}
              />
            </div>
          </div>
          <br></br>
          <button type="submit" className="btn btn-primary">
            {loading ? <Spinner /> : "Update Benefits"}
          </button>
        </form>
        <hr style={{ color: "#E63928", border: "3px solid" }}></hr>
      </div>

      {/* homepagefaqs */}
      <div className="container mt-5">
        <h2 className="mt-4" style={{ textAlign: "center" }}>
          FAQs
        </h2>
        <form onSubmit={handlefaqsubmit}>
          {faqs.map((faq, index) => (
            <div key={index} className="mb-3">
              <input
                type="text"
                name="question"
                className="form-control"
                placeholder="Question"
                value={faq.question}
                onChange={(e) => handlefaqChange(e, index)}
                required
              />
              <input
                type="text"
                name="answer"
                className="form-control mt-2"
                placeholder="Answer"
                value={faq.answer}
                onChange={(e) => handlefaqChange(e, index)}
                required
              />
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() => handlefaqDeleteRow(faq.id)}
              >
                Delete
              </button>
            </div>
          ))}

          <button
            type="button"
            className="btn btn-primary"
            onClick={handlefaqAddRow}
          >
            Add FAQ
          </button>
          <button type="submit" className="btn btn-success" disabled={loading}>
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Submit FAQ"
            )}
          </button>
        </form>
        <hr style={{ color: "#E63928", border: "3px solid" }}></hr>
      </div>
    </>
  );
}

export default ImageUploadForm;
