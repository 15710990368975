import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Tabs, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Accordion, Button } from "react-bootstrap";
import "./Demo.css";
import axios from "axios";

const DashDefault = () => {
  const [leadsData, setLeadsData] = useState({});
  const [leadsPriceData, setLeadsPriceData] = useState([]);
  const [accordianData, setAccordianData] = useState([]);
  const [sheduleData, setSheduleData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);

  const retrivedata = useLocation();
  console.log(retrivedata.state, "retrivedatas");

  const { userdetail } = retrivedata.state || {};
  console.log(userdetail, "userdetailsnew");

  const getleadsdata = async () => {
    const response = await axios.get(
      `https://api.dremerz.net/api/mybankshoppyuserlistforleads/${userdetail.id}`
    );
    console.log(response.data, "leadscount");
    setLeadsData(response.data);
  };
  const getleadsPricedata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadspricetable"
    );
    var data = response.data?.map((item) => {
      item.LoanDetails = JSON.parse(item.LoanDetails);
      return item;
    });
    console.log(response.data, data, "mybankshoppyleadspricetable");

    setLeadsPriceData(data);
  };
  const getaccordiandata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadsaccordiantable"
    );
    var accordiandata = response.data?.map((item) => {
      item.options = JSON.parse(item.options);
      return item;
    });

    console.log(accordiandata, "getaccordiandata");
    setAccordianData(accordiandata);
  };

  const getsheduledata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadsshedule"
    );
    console.log(response.data, "sheduledata");
    setSheduleData(response.data);
  };
  const getadditionaldata = async () => {
    const response = await axios.get(
      "https://api.dremerz.net/api/mybankshoppyleadsadditionalcolumn"
    );
    setAdditionalData(response.data);
  };
  useEffect(() => {
    getleadsdata();
    getleadsPricedata();
    getaccordiandata();
    getsheduledata();
    getadditionaldata();
  }, []);

  return (
    <React.Fragment>
      {/* leads count */}
      <div className="container mt-5">
        <div className="username-container">
          Welcome <span>{leadsData.username}</span>
        </div>
        <h3 className="leadscount-header" style={{ fontSize: "14px" }}>
          Lead Generation Slabs for Commission Basis
        </h3>
        <Row>
          <Col xl={6} xxl={4} className="leadscount-column">
            <Card className="leadscount-card">
              <Card.Body>
                <h6 className="leadscount-title mb-4">Total Leads</h6>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    <h3 className="leadscount-amount d-flex align-items-center m-b-0">
                      {leadsData.totalLeads}
                    </h3>
                  </div>
                  <div className="col-3 text-end leadscount-logo">
                    <img
                      src="assets/images/favicons/mybankshoppylogotitle.png"
                      alt="MyBankShoppy Logo"
                      className="leadscount-logo-img"
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} xxl={4} className="leadscount-column">
            <Card className="leadscount-card">
              <Card.Body>
                <h6 className="leadscount-title mb-4">Total Commission</h6>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    <h3 className="leadscount-amount d-flex align-items-center m-b-0">
                      {leadsData.totalCommissions}
                    </h3>
                  </div>
                  <div className="col-3 text-end leadscount-logo">
                    <img
                      src="assets/images/favicons/mybankshoppylogotitle.png"
                      alt="MyBankShoppy Logo"
                      className="leadscount-logo-img"
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={6} xxl={4} className="leadscount-column">
            <Card className="leadscount-card">
              <Card.Body>
                <h6 className="leadscount-title mb-4">
                  Total Commission Pending
                </h6>
                <div className="row d-flex align-items-center">
                  <div className="col-9">
                    <h3 className="leadscount-amount d-flex align-items-center m-b-0">
                      {leadsData.totalCommissionPending}
                    </h3>
                  </div>
                  <div className="col-3 text-end leadscount-logo">
                    <img
                      src="assets/images/favicons/mybankshoppylogotitle.png"
                      alt="MyBankShoppy Logo"
                      className="leadscount-logo-img"
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>

      <br></br>

      <div className="container mt-5">
        <Row style={{ marginTop: "-60px" }}>
          {leadsPriceData?.map((item, index) => (
            <Col md={6} xl={6} key={index}>
              <Card className="pricetable-card widget-focus-lg">
                <Card.Header className="pricetable-card-header">
                  <Card.Title as="h5">{item.loanType}</Card.Title>
                </Card.Header>
                <Card.Body className="px-0 py-3">
                  <Table responsive hover className="pricetable-table">
                    <tbody>
                      {item.LoanDetails.map((item1, i) => (
                        <tr className="pricetable-row" key={i}>
                          <td>
                            <h6 className="pricetable-title mb-1">
                              {item1.title}
                            </h6>
                          </td>
                          <td>
                            <h6 className="pricetable-price text-muted">
                              <i className="fa fa-circle text-c-green f-10 m-r-15" />
                              &nbsp; Rs. {item1.price}
                            </h6>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <>
        <div className="container mt-5">
          <h2>Lead Approval Conditions & Joining Formalities</h2>

          <div className="accordian-accordion" id="accordianExample">
            {accordianData?.map((item, index) => (
              <div className="accordian-accordion-item" key={index}>
                <h2
                  className="accordian-accordion-header"
                  id={`heading${index}`}
                >
                  <button
                    className={`accordian-accordion-button ${
                      index === 0 ? "" : "collapsed"
                    }`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${index}`}
                    aria-expanded={index === 0 ? "true" : "false"}
                    aria-controls={`collapse${index}`}
                  >
                    {item.heading}
                  </button>
                </h2>
                <div
                  id={`collapse${index}`}
                  className={`accordian-accordion-collapse collapse ${
                    index === 0 ? "show" : ""
                  }`}
                  aria-labelledby={`heading${index}`}
                  data-bs-parent="#accordianExample"
                >
                  <div className="accordian-accordion-body">
                    <ul>
                      {item.options?.map((option, i) => (
                        <li key={i}>{option}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
      <>
        <div className="container mt-5">
          <div className="shedule-section">
            <div className="shedule-header text-center">
              <h2>Schedule</h2>
            </div>
            <div className="row mt-4">
              {sheduleData?.map((item) => (
                <div className="col-md-6 mb-4" key={item.id}>
                  <div className="shedule-card">
                    <h5>{item.title}</h5>
                    <p>{item.detail}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
      <>
        <div className="container mt-5">
          <div className="row additional-column-leads-hours-section">
            {additionalData?.map((item, index) => (
              <div className="col-md-6 col-lg-6 mb-4" key={item.id}>
                <div
                  className={`additional-column-card ${
                    index % 2 === 0 ? "card-left" : "card-right"
                  }`}
                >
                  <div className="additional-column-leads-hours-header">
                    <h2>{item.heading}</h2>
                  </div>
                  <div className="additional-column-leads-hours-card">
                    <p>{item.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
      {/* sample page */}
      <></>
    </React.Fragment>
  );
};

export default DashDefault;
