import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Spinner = () => (
  <div className="spinner-container">
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

const AddBank = () => {
  const [loanOptions, setLoanOptions] = useState([]);
  const [bankName, setBankName] = useState("");
  const [selectedLoan, setSelectedLoan] = useState("");
  const [bankLogo, setBankLogo] = useState(null);
  const [features, setFeatures] = useState([{ feature: "", details: "" }]);
  const [emiCharges, setEmiCharges] = useState([{ title: "", details: "" }]);
  const [documentsRequired, setDocumentsRequired] = useState([
    { document: "" },
  ]);
  const [faqs, setFaqs] = useState([{ question: "", answer: "" }]);
  const [interestRate, setInterestRate] = useState("");
  const [processingFee, setProcessingFee] = useState("");
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState([]);
  const [loan, setLoan] = useState(null);

  const handlebankedit = (loan) => {
    setBankName(loan.bankName);
    setEmiCharges(loan.emiCharges);
    setFeatures(loan.features);
    setDocumentsRequired(loan.documentsRequired);
    setBankLogo(loan.bankLogo);
    setFaqs(loan.faqs);
    setInterestRate(loan.interestRate);
    setProcessingFee(loan.processingFee);
    setLoan(loan);
  };

  const fetchLoans = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppyaddbankloans"
      );
      const bankList = [];

      for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];

        element.features = JSON.parse(element.features);
        element.emiCharges = JSON.parse(element.emiCharges);
        element.documentsRequired = JSON.parse(element.documentsRequired);
        element.faqs = JSON.parse(element.faqs);

        bankList.push(element);
      }
      setLoans(bankList);
    } catch (error) {
      console.error("Error fetching loans:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRow = (setter, data) => {
    setter([...data, {}]);
  };

  const handleDeleteRow = (setter, data, index) => {
    const updatedData = data.filter((_, i) => i !== index);
    setter(updatedData);
  };

  const handleInputChange = (e, index, data, setter) => {
    const { name, value } = e.target;
    const updatedData = [...data];
    updatedData[index][name] = value;
    setter(updatedData);
  };

  const handleFileChange = (e) => {
    setBankLogo(e.target.files[0]);
  };

  const validateNumberInput = (e, setter) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      setter(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedLoanOption = document.getElementById("loanOption").value;

    const formData = new FormData();
    formData.append("loanOption", selectedLoanOption);
    formData.append("bankName", bankName);
    formData.append("bankLogo", bankLogo);
    formData.append("features", JSON.stringify(features));
    formData.append("emiCharges", JSON.stringify(emiCharges));
    formData.append("documentsRequired", JSON.stringify(documentsRequired));
    formData.append("faqs", JSON.stringify(faqs));
    formData.append("interestRate", interestRate);
    formData.append("processingFee", processingFee);

    setLoading(true);
    if (loan == null) {
      axios
        .post("https://api.dremerz.net/api/mybankshoppyaddbankloans", formData)
        .then((response) => {
          console.log("Bank added successfully:", response.data);
          alert("new loan added successfully");
          fetchLoans();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error adding bank:", error);
          setLoading(false);
        });
    } else {
      axios
        .put(
          "https://api.dremerz.net/api/mybankshoppyaddbankloans/" +
            loan.id +
            "/",
          formData
        )
        .then((response) => {
          console.log("Bank Updated successfully:", response.data);
          alert(" loan Updated successfully");
          fetchLoans();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error Updated bank:", error);
          setLoading(false);
        });
    }
  };

  const handleDeleteLoan = async (id) => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this loan?"
    );

    if (!userConfirmed) {
      return; // Exit if user cancels
    }

    setLoading(true);
    try {
      await axios.delete(
        `https://api.dremerz.net/api/mybankshoppyaddbankloans/${id}`
      );
      alert("Loan deleted successfully");
      fetchLoans();
    } catch (error) {
      console.error("Error deleting loan:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoanOprionChange = (e) => {
    console.log(e.target.value, "name");
    setSelectedLoan(e.target.value);
  };

  useEffect(() => {
    axios
      .get("https://api.dremerz.net/api/mybankshoppyservicepagesection")
      .then((response) => {
        setLoanOptions(response.data);
        setSelectedLoan(response.data[0]?.title);
      })
      .catch((error) => console.error(error));

    fetchLoans();
  }, []);

  return (
    <div className="container">
      {loading && <Spinner />}

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="mb-3">
          <label htmlFor="loanOption" className="form-label">
            Loan Option
          </label>
          <select
            id="loanOption"
            className="form-select"
            onChange={handleLoanOprionChange}
          >
            {loanOptions.map((option, index) => (
              <option key={index} value={option.title}>
                {option.title}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="bankName" className="form-label">
            Bank Name
          </label>
          <input
            type="text"
            id="bankName"
            className="form-control"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="bankLogo" className="form-label">
            Bank Logo
          </label>
          <input
            type="file"
            id="bankLogo"
            className="form-control"
            onChange={handleFileChange}
          />
        </div>

        {loan == null ? (
          ""
        ) : (
          <img
            src={`https://api.dremerz.net/uploads/${bankLogo}`}
            style={{ height: "200px", width: "200px" }}
          />
        )}

        {/* Features Table */}
        <h5>Features</h5>
        {features.map((feature, index) => (
          <div key={index} className="mb-3">
            <input
              type="text"
              name="feature"
              className="form-control"
              placeholder="Feature"
              value={feature.feature}
              onChange={(e) =>
                handleInputChange(e, index, features, setFeatures)
              }
            />
            <input
              type="text"
              name="details"
              className="form-control mt-2"
              placeholder="Details"
              value={feature.details}
              onChange={(e) =>
                handleInputChange(e, index, features, setFeatures)
              }
            />
            <button
              type="button"
              className="btn btn-danger mt-2"
              onClick={() => handleDeleteRow(setFeatures, features, index)}
            >
              Delete
            </button>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddRow(setFeatures, features)}
        >
          Add Feature
        </button>

        {/* EMI and Charges Table */}
        <h5 className="mt-4">EMI and Charges</h5>
        {emiCharges.map((charge, index) => (
          <div key={index} className="mb-3">
            <input
              type="text"
              name="title"
              className="form-control"
              placeholder="Title"
              value={charge.title}
              onChange={(e) =>
                handleInputChange(e, index, emiCharges, setEmiCharges)
              }
            />
            <input
              type="text"
              name="details"
              className="form-control mt-2"
              placeholder="Details"
              value={charge.details}
              onChange={(e) =>
                handleInputChange(e, index, emiCharges, setEmiCharges)
              }
            />
            <button
              type="button"
              className="btn btn-danger mt-2"
              onClick={() => handleDeleteRow(setEmiCharges, emiCharges, index)}
            >
              Delete
            </button>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddRow(setEmiCharges, emiCharges)}
        >
          Add EMI Charge
        </button>

        {/* Documents Required Table */}
        <h5 className="mt-4">Documents Required</h5>
        {documentsRequired.map((doc, index) => (
          <div key={index} className="mb-3">
            <input
              type="text"
              name="document"
              className="form-control"
              placeholder="Document Required"
              value={doc.document}
              onChange={(e) =>
                handleInputChange(
                  e,
                  index,
                  documentsRequired,
                  setDocumentsRequired
                )
              }
            />
            <button
              type="button"
              className="btn btn-danger mt-2"
              onClick={() =>
                handleDeleteRow(setDocumentsRequired, documentsRequired, index)
              }
            >
              Delete
            </button>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddRow(setDocumentsRequired, documentsRequired)}
        >
          Add Document
        </button>

        {/* FAQs Table */}
        <h5 className="mt-4">FAQs</h5>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-3">
            <input
              type="text"
              name="question"
              className="form-control"
              placeholder="Question"
              value={faq.question}
              onChange={(e) => handleInputChange(e, index, faqs, setFaqs)}
            />
            <input
              type="text"
              name="answer"
              className="form-control mt-2"
              placeholder="Answer"
              value={faq.answer}
              onChange={(e) => handleInputChange(e, index, faqs, setFaqs)}
            />
            <button
              type="button"
              className="btn btn-danger mt-2"
              onClick={() => handleDeleteRow(setFaqs, faqs, index)}
            >
              Delete
            </button>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleAddRow(setFaqs, faqs)}
        >
          Add FAQ
        </button>
        <br></br>
        <br></br>

        <div className="mb-3">
          <label htmlFor="interestRate" className="form-label">
            Low Interest Rate
          </label>
          <input
            type="text"
            id="interestRate"
            className="form-control"
            value={interestRate}
            onChange={(e) => validateNumberInput(e, setInterestRate)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="processingFee" className="form-label">
            High Interest Rate
          </label>
          <input
            type="text"
            id="processingFee"
            className="form-control"
            value={processingFee}
            onChange={(e) => validateNumberInput(e, setProcessingFee)}
            required
          />
        </div>

        <div>
          {loan == null ? (
            <button type="submit" className="btn btn-primary">
              Add Bank
            </button>
          ) : (
            <button type="submit" className="btn btn-primary">
              update
            </button>
          )}
        </div>
      </form>

      {/* Display Loans */}
      <h4 className="mt-4">Existing Loans</h4>
      {loading ? (
        <Spinner />
      ) : loans.length > 0 ? (
        <ul className="list-group">
          {loans.map((loan) =>
            loan.loanOption?.toUpperCase() === selectedLoan?.toUpperCase() ? (
              <li
                key={loan.id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>
                  {loan.bankName} - {loan.loanOption}
                </span>

                <span>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handlebankedit(loan)}
                  >
                    edit
                  </button>

                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDeleteLoan(loan.id)}
                  >
                    Delete
                  </button>
                </span>
              </li>
            ) : (
              ""
            )
          )}
        </ul>
      ) : (
        <p>No loans available.</p>
      )}
    </div>
  );
};

export default AddBank;
