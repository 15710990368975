import React from "react";

function Maptwo() {
  return (
    <>
      <div className="google-map__default">
        <iframe
          title="template google map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3926.743919593721!2d80.0610619!3d13.1180668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTPCsDA3JzA1LjAiTiA4MMKwMDMnNDkuMSJF!5e0!3m2!1sen!2sin!4v1691612926144!5m2!1sen!2sin"
          className="map__default"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}

export default Maptwo;
