import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Eligibilitycalc.css";
import Newcalc from "./Newcalc";

const Eligibilitycalc = (props) => {
  const [employmentType, setEmploymentType] = useState(
    props.data.employmentType?.label
  );
  const [loans, setLoans] = useState([
    { type: "", amount: "", paid: "", balance: "", emi: "" },
  ]);
  const [monthlySalary, setMonthlySalary] = useState(0);
  const [loanOffer, setLoanOffer] = useState(null);

  const employmentMultipliers = {
    Salaried: 1.0,
    SelfemployedProfessional: 0.9,
    BusinessOwner: 0.85,
    IndependentWorker: 0.8,
    Student: 0.6,
    Retired: 0.7,
    Homemaker: 0.5,
    Others: 0.4,
  };

  const handleAddLoan = () => {
    setLoans([...loans, { type: "", amount: 0, paid: 0, balance: 0, emi: 0 }]);
  };

  const handleLoanChange = (index, field, value) => {
    const newLoans = loans.map((loan, i) =>
      i === index ? { ...loan, [field]: value } : loan
    );
    setLoans(newLoans);
  };

  const calculateLoanOffer = () => {
    const totalLoanAmount = loans.reduce(
      (acc, loan) => acc + Number(loan.amount),
      0
    );
    const totalPaid = loans.reduce((acc, loan) => acc + Number(loan.paid), 0);
    const totalBalance = loans.reduce(
      (acc, loan) => acc + Number(loan.balance),
      0
    );
    const totalEMI = loans.reduce((acc, loan) => acc + Number(loan.emi), 0);
    const dti = (totalEMI / monthlySalary) * 100;

    const maxPermissibleEMI = monthlySalary * 0.4;
    const remainingEMI = maxPermissibleEMI - totalEMI;

    const minInterestRate = 8;
    const maxInterestRate = 14;

    const rMin = minInterestRate / (12 * 100);
    const rMax = maxInterestRate / (12 * 100);
    const n = 5 * 12;

    const employmentMultiplier = employmentMultipliers[employmentType] || 1;

    const maxLoanAmountMinInterest =
      ((remainingEMI * ((1 + rMin) ** n - 1)) / (rMin * (1 + rMin) ** n)) *
      employmentMultiplier;

    const maxLoanAmountMaxInterest =
      ((remainingEMI * ((1 + rMax) ** n - 1)) / (rMax * (1 + rMax) ** n)) *
      employmentMultiplier;

    const adjustedMinLoanAmount = Math.max(0, maxLoanAmountMinInterest);
    const adjustedMaxLoanAmount = Math.max(0, maxLoanAmountMaxInterest);

    setLoanOffer({
      totalLoanAmount,
      totalPaid,
      totalBalance,
      minLoanAmount: Math.round(adjustedMinLoanAmount),
      maxLoanAmount: Math.round(adjustedMaxLoanAmount),
      minInterestRate,
      maxInterestRate,
      dti: dti.toFixed(2),
    });
  };

  useEffect(() => {
    console.log(props.data.employmentType?.label);

    setEmploymentType(props.data.employmentType?.label);
  }, [props]);

  return (
    // <div className="container mt-5 eligibility-container">
    //   <h2 className="text-center mb-4">Loan Eligibility Calculator</h2>
    //   <div className="mb-3">
    //     <label className="form-label">Employment Type</label>
    //     <select
    //       className="form-select"
    //       value={employmentType}
    //       onChange={(e) => setEmploymentType(e.target.value)}
    //     >
    //       <option value="SelfemployedProfessional">
    //         Selfemployed Professional
    //       </option>

    //       <option value="Salaried">Salaried</option>
    //       <option value="BusinessOwner">Business Owner</option>
    //       <option value="IndependentWorker">Independent Worker</option>
    //       <option value="Student">Student</option>
    //       <option value="Retired">Retired</option>
    //       <option value="Homemaker">Homemaker</option>
    //       <option value="Others">Others</option>
    //     </select>
    //   </div>
    //   <div className="mb-3 mt-3">
    //     <label className="form-label">
    //       Enter Monthly Net Salary or TurnOver
    //     </label>
    //     <input
    //       type="number"
    //       className="form-control"
    //       value={monthlySalary}
    //       onChange={(e) => setMonthlySalary(e.target.value)}
    //     />
    //   </div>
    //   <div>
    //     <label className="form-label">Previous Loan Details</label>
    //     {loans.map((loan, index) => (
    //       <div key={index} className="mb-3 p-3 border rounded">
    //         <div className="mb-2">
    //           <input
    //             type="text"
    //             className="form-control eligi-calc"
    //             placeholder="Enter Loan Type"
    //             value={loan.type}
    //             onChange={(e) =>
    //               handleLoanChange(index, "type", e.target.value)
    //             }
    //           />
    //         </div>
    //         <div className="mb-2">
    //           <input
    //             type="number"
    //             className="form-control  eligi-calc"
    //             placeholder="Enter Loan Amount"
    //             value={loan.amount}
    //             onChange={(e) =>
    //               handleLoanChange(index, "amount", e.target.value)
    //             }
    //           />
    //         </div>
    //         <div className="mb-2">
    //           <input
    //             type="number"
    //             className="form-control eligi-calc"
    //             placeholder="Enter Paid Amount"
    //             value={loan.paid}
    //             onChange={(e) =>
    //               handleLoanChange(index, "paid", e.target.value)
    //             }
    //           />
    //         </div>
    //         <div className="mb-2">
    //           <input
    //             type="number"
    //             className="form-control eligi-calc"
    //             placeholder="Enter Balance Amount"
    //             value={loan.balance}
    //             onChange={(e) =>
    //               handleLoanChange(index, "balance", e.target.value)
    //             }
    //           />
    //         </div>
    //         <div className="mb-2">
    //           <input
    //             type="number"
    //             className="form-control eligi-calc"
    //             placeholder="Enter Monthly EMI"
    //             value={loan.emi}
    //             onChange={(e) => handleLoanChange(index, "emi", e.target.value)}
    //           />
    //         </div>
    //       </div>
    //     ))}
    //     <button className="calc-add-button" onClick={handleAddLoan}>
    //       Add Another Loan
    //     </button>
    //   </div>

    //   <button className="calc-calculate-button" onClick={calculateLoanOffer}>
    //     Calculate Loan Offer
    //   </button>

    //   {loanOffer && (
    //     <div className="modal show d-block" tabIndex="-1">
    //       <div className="modal-dialog">
    //         <div className="modal-content">
    //           <div className="modal-header">
    //             <h5 className="modal-title">Loan Offer Details</h5>
    //             <button
    //               type="button"
    //               className="btn-close"
    //               aria-label="Close"
    //               onClick={() => setLoanOffer(null)}
    //             ></button>
    //           </div>
    //           <div className="modal-body">
    //             <h4>You are Eligible To Get Loan Offer</h4>
    //             <p>Total Loan Amount: {loanOffer.totalLoanAmount}</p>
    //             <p>Total Paid Amount: {loanOffer.totalPaid}</p>
    //             <p>Total Balance Amount: {loanOffer.totalBalance}</p>
    //             <p>Debt-to-Income Ratio: {loanOffer.dti}%</p>

    //             <p>
    //               At Interest Rate Range From: {loanOffer.minInterestRate}% -
    //               {loanOffer.maxInterestRate}%
    //             </p>

    //             <div className="prize-box-container text-center">
    //               <div className="prize-box">
    //                 <p className="prize-title">Maximum Loan Amount:</p>
    //                 <div className="prize-amount">
    //                   Rs. {loanOffer.maxLoanAmount} to Rs.
    //                   {loanOffer.minLoanAmount}
    //                 </div>
    //                 <div className="confetti"></div>
    //                 {/* For animation effect */}
    //               </div>
    //             </div>
    //           </div>
    //           <div className="modal-footer">
    //             <button
    //               type="button"
    //               className="btn btn-secondary"
    //               onClick={() => setLoanOffer(null)}
    //             >
    //               Close
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </div>

    <Newcalc />
  );
};

export default Eligibilitycalc;
