import React, { useEffect, useState } from "react";
import axios from "axios";

function Benefit() {
  const [benefitsData, setBenefitsData] = useState({});
  //get data
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppybenefitssection"
      );
      console.log(response.data, "benefits");
      setBenefitsData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);

  return (
    <>
      <section className="benefit-one pt-120">
        <div
          className="benefit-one__shape-1 wow fadeInLeft"
          data-wow-delay="000ms"
          data-wow-duration="1500ms"
          style={{
            backgroundImage: `url(https://api.dremerz.net/uploads/${benefitsData?.input1})`,
          }}
        ></div>
        <div className="benefit-one__shape-2"></div>
        <div className="container">
          <div className="row row-gutter-y-60">
            <div className="col-lg-6">
              <div
                className="benefit-one__image wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="500ms"
              >
                <img
                  src={`https://api.dremerz.net/uploads/${benefitsData?.input1}`}
                  alt=""
                />
                <div className="benefit-one__image__caption">
                  <h3 className="benefit-one__image__title">99.9%</h3>
                  <p className="benefit-one__image__text">
                    Success Rates Guarantee
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="benefit-one__content">
                <div className="block-title text-left">
                  <p className="block-title__tagline">{benefitsData.input2}</p>
                  <h2 className="block-title__title">{benefitsData.input3} </h2>
                </div>
                <p className="benefit-one__text">{benefitsData.input4} </p>
                <div className="benefit-one__box">
                  <div className="benefit-one__box__icon">
                    <i className="icon-bank"></i>
                  </div>
                  <div className="benefit-one__box__content">
                    <h3 className="benefit-one__box__title">
                      {benefitsData.input5}{" "}
                    </h3>
                    <p className="benefit-one__box__text">
                      {benefitsData.input6}
                    </p>
                  </div>
                </div>
                <div className="benefit-one__box">
                  <div className="benefit-one__box__icon">
                    <i className="icon-payment"></i>
                  </div>
                  <div className="benefit-one__box__content">
                    <h3 className="benefit-one__box__title">
                      {benefitsData.input7}
                    </h3>

                    <p className="benefit-one__box__text">
                      {benefitsData.input8}
                    </p>
                  </div>
                </div>
                <div className="benefit-one__box">
                  <div className="benefit-one__box__icon">
                    <i className="icon-smartphone-1"></i>
                  </div>
                  <div className="benefit-one__box__content">
                    <h3 className="benefit-one__box__title">
                      {benefitsData.input9}
                    </h3>
                    <p className="benefit-one__box__text">
                      {benefitsData.input10}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Benefit;
