import { useEffect, useState } from "react";
import axios from "axios";

const BankList = () => {
  const [bankData, setBankData] = useState([]); // Initialize state as an array

  // Function to get data
  const getData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppybankdetails"
      );
      setBankData(response.data);
      console.log(response.data, "bankData");
    } catch (error) {
      console.error("Error fetching bank data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="container mt-5">
        <table className="table table-striped table-hover">
          <thead className="thead-dark">
            <tr>
              <th scope="col">BANK LOGO</th>
              <th scope="col">BANK NAME</th>
              <th scope="col">INTEREST RATE</th>
              <th scope="col">PROCESSING FEE</th>
              <th scope="col">MAX TENURE</th>
              <th scope="col">MAX LOAN AMOUNT</th>
              <th scope="col">CATEGORY</th>
            </tr>
          </thead>
          <tbody>
            {bankData.map((bank, index) => (
              <tr key={index}>
                <td>
                  {bank.image && (
                    <img
                      src={bank.image}
                      alt={bank.name}
                      style={{ width: "50px", marginRight: "10px" }}
                    />
                  )}
                </td>
                <td>{bank.name}</td>
                <td>{bank.interestRate}</td>
                <td>{bank.processingFee}</td>
                <td>{bank.maxTenure}</td>
                <td>{bank.maxLoanAmount}</td>
                <td>{bank.category}</td>
                <td>{/* Add any actions such as edit or delete here */}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BankList;
