import { Link } from "react-router-dom";
import React, { useState } from "react";

function First1() {
  const [lowrate, setLowrate] = useState(0);
  const [highrate, setHighrate] = useState(0);
  const [principalrate, setPrincipalrate] = useState(50000);
  const [loanyear, setLoanyear] = useState(3);

  const loancalculate = (principal_rate, interest_rates, loan_year) => {
    const loanAmount = principal_rate;
    const numberOfMonths = loan_year * 12;
    const rateOfInterest = interest_rates;
    const monthlyInterestRatio = rateOfInterest / 100 / 12;
    const top = Math.pow(1 + monthlyInterestRatio, numberOfMonths);
    const bottom = top - 1;
    const sp = top / bottom;
    const emi = loanAmount * monthlyInterestRatio * sp;
    const full = numberOfMonths * emi;
    const interest = full - loanAmount;
    const int_pge = (interest / full) * 100;

    const emi_str = emi
      .toFixed(2)
      .toString()
      .replace(/,/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return emi_str;
  };

  const loancal = (lowInt, highInt) => {
    if (principalrate > 0 && loanyear > 0) {
      if (lowInt && highInt) {
        const low_emi = loancalculate(principalrate, lowInt, loanyear);
        const high_emi = loancalculate(principalrate, highInt, loanyear);
        return `INR ${low_emi} - ${high_emi}`;
      } else if (!lowInt && highInt) {
        const high_emi = loancalculate(principalrate, highInt, loanyear);
        return `INR ${high_emi}`;
      } else if (lowInt && !highInt) {
        const low_emi = loancalculate(principalrate, lowInt, loanyear);
        return `INR ${low_emi}`;
      }
    }
  };

  const compardata = [
    {
      id: 1,
      name: "ICICI Bank",
      lowInt: 15,
      highInt: 25,
      fees: "2%",
    },
    {
      id: 2,
      name: "HDFC Bank",
      lowInt: 15,
      highInt: 24,
      fees: "2%",
    },
    {
      id: 3,
      name: "AXIS Bank",
      lowInt: 15,
      highInt: 24,
      fees: "2%",
    },
    {
      id: 4,
      name: "IDFC Bank",
      lowInt: 17,
      highInt: 28,
      fees: "2.50%",
    },
    {
      id: 5,
      name: "NDFC Bank",
      lowInt: 0,
      highInt: 0,
      fees: "0%",
    },
  ];

  return (
    <>
      <section className="loan-Calculator pt-120 pb-120">
        <div className="container">
          <div className="loan-calculator__top">
            <div className="row">
              <div className="col-md-6">
                <div className="block-title text-left">
                  <p className="block-title__tagline">Compare Your EMI</p>
                  <h2 className="block-title__title">
                    Comparison With Other Banks
                  </h2>
                </div>
              </div>
              <div className="col-md-6">
                <p className="loan-calculator__top__text">
                  Looking for the best EMI rates for your business loan? Compare
                  our competitive rates with those from other lenders and find
                  out how much more you can save.
                </p>
              </div>
            </div>
          </div>
          <div className="loan-comparison__body" id="compare-emi-1">
            <div className="row">
              <div className="col-lg-6">
                <div className="slider-box">
                  <div className="main-slide">
                    <div className="single-loan-header">
                      <h4>Loan Amount</h4>
                      <input
                        className="form-control input-control"
                        type="text"
                        value={principalrate}
                        onChange={(e) => setPrincipalrate(e.target.value)}
                      />
                    </div>
                    <div className="w-100">
                      <input
                        style={{ maxWidth: "100%" }}
                        className="w-100 slider"
                        type="range"
                        min="1000"
                        max="500000"
                        value={principalrate}
                        onChange={(e) => setPrincipalrate(e.target.value)}
                      />
                    </div>
                    <div className="loan-counter-value">
                      <span>INR {principalrate}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slider-box">
                  <div className="main-slide">
                    <div className="single-loan-header">
                      <h4>Loan Years</h4>
                      <input
                        className="form-control input-control"
                        type="text"
                        value={loanyear}
                        onChange={(e) => setLoanyear(e.target.value)}
                      />
                    </div>
                    <div className="w-100">
                      <input
                        style={{ maxWidth: "100%" }}
                        className="w-100 slider"
                        type="range"
                        min="1"
                        max="24"
                        value={loanyear}
                        onChange={(e) => setLoanyear(e.target.value)}
                      />
                    </div>
                    <div className="loan-counter-value">
                      <span>{loanyear} Years</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead className="thead-dark">
                      <tr>
                        <th>Banks</th>
                        <th>Interest Rate (p.a.)</th>
                        <th>Processing Fees</th>
                        <th>EMI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {compardata.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <Link to="#">{item.name}</Link>
                          </td>
                          <td>
                            {item.lowInt}% - {item.highInt}%
                          </td>
                          <td>{item.fees}</td>
                          <td>{loancal(item.lowInt, item.highInt)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="lowest-emi-note">
            <span className="indicator"></span>
            <small>This indicates lowest emi.</small>
          </div>
          <div className="terms-conditions">
            <small>**Terms and conditions apply as per banks.</small>
          </div>
        </div>
      </section>
    </>
  );
}

export default First1;
