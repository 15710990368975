import React, { useState } from "react";
import { Table, FormControl, InputGroup, Button } from "react-bootstrap";
import "./Newcalc.css";
import { useNavigate } from "react-router-dom";

const LoanCalculator = () => {
  const [netSalaries, setNetSalaries] = useState([{ salary: "" }]);
  const [obligations, setObligations] = useState([
    { product: "", value: "", emi: "" },
  ]);
  const [loanEligibility, setLoanEligibility] = useState({
    loanAmount: 0,
    interestRate: 0,
  });
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    personalLoan1: { value: null, emi: null },
    personalLoan2: { value: null, emi: null },
    personalLoan3: { value: null, emi: null },
    homeLoan1: { value: null, emi: null },
    homeLoan2: { value: null, emi: null },
    homeLoan3: { value: null, emi: null },
    creditCardOs: { value: null, emi: null },
    goldLoan: { value: null, emi: null },
  });

  // const handleChange = (event, fieldName, type) => {
  //   const { value } = event.target;

  //   setFormValues((prev) => ({
  //     ...prev,
  //     [fieldName]: { ...prev[fieldName], [type]: Number(value) },
  //   }));
  // };
  const handleChange = (event, fieldName, type) => {
    const { value } = event.target;

    const numericValue = value === "" ? "" : Number(value);

    setFormValues((prev) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], [type]: numericValue },
    }));
  };

  const calculateTotalBurden = () => {
    const totalValue = Object.values(formValues).reduce(
      (acc, { value }) => acc + (value || 0),
      0
    );
    const totalEmi = Object.values(formValues).reduce(
      (acc, { emi }) => acc + (emi || 0),
      0
    );
    return { totalValue, totalEmi };
  };

  const { totalValue, totalEmi } = calculateTotalBurden();

  const redirect = () => {
    navigate("/applynow");
  };

  const addNetSalary = () => {
    setNetSalaries([...netSalaries, { salary: "" }]);
  };

  const addObligation = () => {
    setObligations([...obligations, { product: "", value: "", emi: "" }]);
  };

  const calculateLoanEligibility = () => {
    const totalSalary = netSalaries.reduce(
      (acc, curr) => acc + parseFloat(curr.salary || 0),
      0
    );

    const incomeToDebtRatio = totalEmi / totalSalary;

    let loanAmount = 0;
    let interestRate = 0;

    if (incomeToDebtRatio < 0.3) {
      loanAmount = totalSalary * 5;
      interestRate = "10 to 16";
    } else if (incomeToDebtRatio < 0.5) {
      loanAmount = totalSalary * 12;
      interestRate = 14;
    } else {
      loanAmount = totalSalary * 1.5;
      interestRate = 16;
    }

    setLoanEligibility({ loanAmount, interestRate });
    setShowPopup(true);
  };

  const reset = () => {
    setNetSalaries([{ salary: "" }]);
    setObligations([{ product: "", value: "", emi: "" }]);
    setLoanEligibility({ loanAmount: 0, interestRate: 0 });
    setFormValues({
      personalLoan1: { value: 0, emi: 0 },
      personalLoan2: { value: 0, emi: 0 },
      personalLoan3: { value: 0, emi: 0 },
      homeLoan1: { value: 0, emi: 0 },
      homeLoan2: { value: 0, emi: 0 },
      homeLoan3: { value: 0, emi: 0 },
      creditCardOs: { value: 0, emi: 0 },
      goldLoan: { value: 0, emi: 0 },
    });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    // <div>
    //   <div className="loan-calculator-container">
    //     <h3 className="loan-calculator-title">Loan Eligibility Calculator</h3>

    //     <Table className="loan-calculator-table">
    //       <thead>
    //         <tr>
    //           <th>
    //             Net Salary <br></br>(Multiple Incomes)
    //           </th>
    //           <th>
    //             Obligations <br></br>(Products, Values, EMI)
    //           </th>
    //           <th>
    //             Total <br></br> Loan Eligibility
    //           </th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         <tr>
    //           <td data-label="Net Salary">
    //             {netSalaries.map((item, index) => (
    //               <InputGroup
    //                 className="loan-calculator-input-group"
    //                 key={index}
    //               >
    //                 <FormControl
    //                   type="number"
    //                   placeholder="Enter Net Salary"
    //                   value={item.salary}
    //                   onChange={(e) => {
    //                     const updatedSalaries = [...netSalaries];
    //                     updatedSalaries[index].salary = e.target.value;
    //                     setNetSalaries(updatedSalaries);
    //                   }}
    //                 />
    //               </InputGroup>
    //             ))}
    //             <Button
    //               className="loan-calculator-button"
    //               onClick={addNetSalary}
    //             >
    //               Add More Salary
    //             </Button>
    //           </td>

    //           <td data-label="Obligations">
    //             <div className="container mt-4">
    //               <table className="table table-bordered table-hover">
    //                 <thead className="thead-light">
    //                   <tr>
    //                     <th scope="col">Loan Type</th>
    //                     <th scope="col">Total Value</th>
    //                     <th scope="col">Total EMI</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   {["personalLoan1", "personalLoan2", "personalLoan3"].map(
    //                     (loan, index) => (
    //                       <tr key={loan}>
    //                         <td style={{ fontSize: "12px" }}>{`Personal Loan ${
    //                           index + 1
    //                         }`}</td>
    //                         <td>
    //                           <input
    //                             type="number"
    //                             className="form-control form-control-sm obligations-input"
    //                             value={formValues[loan].value}
    //                             onChange={(e) => handleChange(e, loan, "value")}
    //                           />
    //                         </td>
    //                         <td>
    //                           <input
    //                             type="number"
    //                             className="form-control form-control-sm obligations-input"
    //                             value={formValues[loan].emi}
    //                             onChange={(e) => handleChange(e, loan, "emi")}
    //                           />
    //                         </td>
    //                       </tr>
    //                     )
    //                   )}

    //                   {["homeLoan1", "homeLoan2", "homeLoan3"].map(
    //                     (loan, index) => (
    //                       <tr key={loan}>
    //                         <td style={{ fontSize: "13px" }}>{`Home Loan ${
    //                           index + 1
    //                         }`}</td>
    //                         <td>
    //                           <input
    //                             type="number"
    //                             className="form-control form-control-sm obligations-input"
    //                             value={formValues[loan].value}
    //                             onChange={(e) => handleChange(e, loan, "value")}
    //                           />
    //                         </td>
    //                         <td>
    //                           <input
    //                             type="number"
    //                             className="form-control form-control-sm obligations-input"
    //                             value={formValues[loan].emi}
    //                             onChange={(e) => handleChange(e, loan, "emi")}
    //                           />
    //                         </td>
    //                       </tr>
    //                     )
    //                   )}

    //                   <tr>
    //                     <td style={{ fontSize: "12px" }}>Credit Card O/S</td>
    //                     <td>
    //                       <input
    //                         type="number"
    //                         className="form-control form-control-sm obligations-input"
    //                         value={formValues.creditCardOs.value}
    //                         onChange={(e) =>
    //                           handleChange(e, "creditCardOs", "value")
    //                         }
    //                       />
    //                     </td>
    //                     <td>
    //                       <input
    //                         type="number"
    //                         className="form-control form-control-sm obligations-input"
    //                         value={formValues.creditCardOs.emi}
    //                         onChange={(e) =>
    //                           handleChange(e, "creditCardOs", "emi")
    //                         }
    //                       />
    //                     </td>
    //                   </tr>

    //                   <tr>
    //                     <td style={{ fontSize: "14px" }}>Gold Loan</td>
    //                     <td>
    //                       <input
    //                         type="number"
    //                         className="form-control form-control-sm obligations-input"
    //                         value={formValues.goldLoan.value}
    //                         onChange={(e) =>
    //                           handleChange(e, "goldLoan", "value")
    //                         }
    //                       />
    //                     </td>
    //                     <td>
    //                       <input
    //                         type="number"
    //                         className="form-control form-control-sm obligations-input"
    //                         value={formValues.goldLoan.emi}
    //                         onChange={(e) => handleChange(e, "goldLoan", "emi")}
    //                       />
    //                     </td>
    //                   </tr>

    //                   <tr className="font-weight-bold">
    //                     <td>Total Burden</td>
    //                     <td>{totalValue}</td>
    //                     <td>{totalEmi}</td>
    //                   </tr>
    //                 </tbody>
    //               </table>
    //             </div>
    //           </td>

    //           <td data-label="Total and Loan Eligibility">
    //             <div className="loan-calculator-results">
    //               <div>
    //                 <strong>Total Salary: </strong>
    //                 {netSalaries.reduce(
    //                   (acc, curr) => acc + parseFloat(curr.salary || 0),
    //                   0
    //                 )}
    //               </div>
    //               <div>
    //                 <strong>Total Obligation Value: </strong>
    //                 {totalValue}
    //               </div>
    //               <div>
    //                 <strong>Total EMI: </strong>
    //                 {totalEmi}
    //               </div>
    //               <div>
    //                 <Button
    //                   className="loan-calculator-button"
    //                   onClick={calculateLoanEligibility}
    //                 >
    //                   Calculate Loan
    //                 </Button>
    //               </div>
    //               <div>
    //                 <Button onClick={reset} className="loan-calculator-button">
    //                   Reset
    //                 </Button>
    //               </div>
    //             </div>
    //           </td>
    //         </tr>
    //       </tbody>
    //     </Table>
    //   </div>

    //   {showPopup && (
    //     <div className="popup-overlay">
    //       <div className="popup-content">
    //         <h2>Loan Eligibility</h2>
    //         <p>
    //           Based on your income and obligations, you are eligible for a loan
    //           amount of Rs. {loanEligibility.loanAmount} with an interest rate
    //           of {loanEligibility.interestRate}%.
    //         </p>
    //         <div className="popup-buttons">
    //           <Button className="loan-calculator-button" onClick={closePopup}>
    //             Close
    //           </Button>
    //           <Button className="loan-calculator-button" onClick={redirect}>
    //             Claim Now
    //           </Button>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </div>

    <div className="loan-calculator-container">
      <h3 className="loan-calculator-title">Loan Eligibility Calculator</h3>

      <Table className="loan-calculator-table">
        <thead>
          <tr>
            <th>Net Salary (Multiple Incomes)</th>
            <th>Obligations (Products, Values, EMI)</th>
            <th>Total Loan Eligibility</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-label="Net Salary">
              {netSalaries.map((item, index) => (
                <InputGroup className="loan-calculator-input-group" key={index}>
                  <FormControl
                    type="number"
                    placeholder="Enter Net Salary"
                    value={item.salary}
                    onChange={(e) => {
                      const updatedSalaries = [...netSalaries];
                      updatedSalaries[index].salary = e.target.value;
                      setNetSalaries(updatedSalaries);
                    }}
                  />
                </InputGroup>
              ))}
              <Button className="loan-calculator-button" onClick={addNetSalary}>
                Add More Salary
              </Button>
            </td>

            <td data-label="Obligations">
              <div className="table-responsive">
                <Table className="table table-bordered table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th>Loan Type</th>
                      <th>Total Value</th>
                      <th>Total EMI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {["personalLoan1", "personalLoan2", "personalLoan3"].map(
                      (loan, index) => (
                        <tr key={loan}>
                          <td>Personal Loan {index + 1}</td>
                          <td>
                            <input
                              type="number"
                              className="form-control form-control-sm obligations-input"
                              value={formValues[loan].value}
                              onChange={(e) => handleChange(e, loan, "value")}
                              placeholder="Enter Total Value"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control form-control-sm obligations-input"
                              value={formValues[loan].emi}
                              onChange={(e) => handleChange(e, loan, "emi")}
                              placeholder="Enter Emi"
                            />
                          </td>
                        </tr>
                      )
                    )}
                    {["homeLoan1", "homeLoan2", "homeLoan3"].map(
                      (loan, index) => (
                        <tr key={loan}>
                          <td>Home Loan {index + 1}</td>
                          <td>
                            <input
                              type="number"
                              className="form-control form-control-sm obligations-input"
                              value={formValues[loan].value}
                              onChange={(e) => handleChange(e, loan, "value")}
                              placeholder="Enter Total Value"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              className="form-control form-control-sm obligations-input"
                              value={formValues[loan].emi}
                              placeholder="Enter Emi"
                              onChange={(e) => handleChange(e, loan, "emi")}
                            />
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td>Credit Card O/S</td>
                      <td>
                        <input
                          type="number"
                          className="form-control form-control-sm obligations-input"
                          value={formValues.creditCardOs.value}
                          placeholder="Enter Total Value"
                          onChange={(e) =>
                            handleChange(e, "creditCardOs", "value")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control form-control-sm obligations-input"
                          value={formValues.creditCardOs.emi}
                          placeholder="Enter Emi"
                          onChange={(e) =>
                            handleChange(e, "creditCardOs", "emi")
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Gold Loan</td>
                      <td>
                        <input
                          type="number"
                          className="form-control form-control-sm obligations-input"
                          value={formValues.goldLoan.value}
                          placeholder="Enter Total Value"
                          onChange={(e) => handleChange(e, "goldLoan", "value")}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="form-control form-control-sm obligations-input"
                          value={formValues.goldLoan.emi}
                          placeholder="Enter Emi"
                          onChange={(e) => handleChange(e, "goldLoan", "emi")}
                        />
                      </td>
                    </tr>
                    <tr className="font-weight-bold">
                      <td>Total Burden</td>
                      <td>{totalValue}</td>
                      <td>{totalEmi}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </td>

            <td data-label="Total and Loan Eligibility">
              <div className="loan-calculator-results">
                <div>
                  <strong>Total Salary: </strong>
                  {netSalaries.reduce(
                    (acc, curr) => acc + parseFloat(curr.salary || 0),
                    0
                  )}
                </div>
                <div>
                  <strong>Total Obligation Value: </strong>
                  {totalValue}
                </div>
                <div>
                  <strong>Total EMI: </strong>
                  {totalEmi}
                </div>
                <Button
                  className="loan-calculator-button"
                  onClick={calculateLoanEligibility}
                >
                  Calculate Loan
                </Button>
                <Button className="loan-calculator-button" onClick={reset}>
                  Reset
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Loan Eligibility</h2>
            <p>
              Based on your income and obligations, <br></br>you are eligible
              for a loan amount of Rs. {loanEligibility.loanAmount} <br></br>
              with an interest rate of {loanEligibility.interestRate}%.
            </p>
            <div className="popup-buttons">
              <Button className="loan-calculator-button" onClick={closePopup}>
                Close
              </Button>
              <Button className="loan-calculator-button" onClick={redirect}>
                Claim Now
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanCalculator;
