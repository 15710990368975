import React, { useEffect, useState } from "react";
import axios from "axios";

function Map() {
  const [contactData, setContactData] = useState({});

  //get data
  const getdata = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppycontactuspagesection"
      );
      console.log(response.data);
      setContactData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <>
      <section className="contact-info-one">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-telephone"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Have any question?</p>
                  <a
                    className="contact-info-one__link"
                    href="tel:+91 9884063673"
                  >
                    {contactData.input6}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-email"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Write us email</p>
                  <a
                    className="contact-info-one__link"
                    href="mailto:customersupport@mybankshoppy.com"
                  >
                    {contactData.input7}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="contact-info-one__item">
                <div className="contact-info-one__icon">
                  <i className="icon-pin"></i>
                </div>
                <div className="contact-info-one__content">
                  <p className="contact-info-one__text">Visit anytime</p>
                  <a
                    className="contact-info-one__link"
                    href="https://www.google.com/maps/@13.118056,80.063639,16z?hl=en&entry=ttu"
                  >
                    {contactData.input8}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Map;
