import React, { useState } from "react";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import "./User.css";

const User = (props) => {
  const [formData, setFormData] = useState({
    fullname: props.data.fullName || "",
    email: props.data.email || "",
    mobilenumber: props.data.phone || "",
    pincode: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    const { fullname, email, mobilenumber, pincode } = formData;

    if (!fullname) newErrors.fullname = "Fullname is required.";
    if (!email || !/\S+@\S+\.\S+/.test(email))
      newErrors.email = "Valid email is required.";
    if (!mobilenumber || mobilenumber.length !== 10)
      newErrors.mobilenumber = "Mobilenumber must be exactly 10 digits.";
    if (pincode.length !== 6 || isNaN(pincode))
      newErrors.pincode = "Pincode must be exactly 6 numeric digits.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      props.setData((prevData) => ({
        ...prevData,
        fullName: formData.fullname,
        email: formData.email,
        phone: formData.mobilenumber,
      }));
      props.setstep(3);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="user-form">
      <h2 style={{ textAlign: "center", color: "#E63928" }}>
        Personal Details
      </h2>
      <Form.Group controlId="formFullname">
        <Form.Label>
          <i class="fas fa-user"></i> Fullname
        </Form.Label>
        <FormControl
          type="text"
          placeholder="Enter your fullname"
          name="fullname"
          value={formData.fullname}
          onChange={handleChange}
          className="user-input"
          isInvalid={!!errors.fullname}
        />
        {errors.fullname && (
          <Form.Control.Feedback type="invalid">
            {errors.fullname}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="formEmail">
        <Form.Label>
          <i class="fas fa-envelope"></i>Email
        </Form.Label>
        <FormControl
          type="email"
          placeholder="Enter your email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="user-input"
          isInvalid={!!errors.email}
        />
        {errors.email && (
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="formMobilenumber">
        <Form.Label>
          <i class="fas fa-phone"></i>Mobilenumber
        </Form.Label>
        <FormControl
          type="text"
          placeholder="Enter your mobilenumber"
          name="mobilenumber"
          value={formData.mobilenumber}
          onChange={handleChange}
          maxLength={10}
          className="user-input"
          isInvalid={!!errors.mobilenumber}
        />
        {errors.mobilenumber && (
          <Form.Control.Feedback type="invalid">
            {errors.mobilenumber}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group controlId="formPincode">
        <Form.Label>
          <i class="fas fa-map-pin"></i>Pincode
        </Form.Label>
        <FormControl
          className="pincode-input"
          type="text"
          placeholder="Enter your pincode"
          name="pincode"
          value={formData.pincode}
          onChange={handleChange}
          pattern="\d{6}"
          title="Please enter exactly 6 digits"
          maxLength="6"
          isInvalid={!!errors.pincode}
        />
        {errors.pincode && (
          <Form.Control.Feedback type="invalid">
            {errors.pincode}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <br />

      <Button type="submit" className="user-button">
        Submit
      </Button>
    </Form>
  );
};

export default User;
