import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";

function ContactusUploadForm() {
  const [contactsData, setcontactsData] = useState({});
  const [aboutUs, setAbouUs] = useState(null);
  const [abouUsBannerData, setAbouUsBannerData] = useState(null);
  const [deviceType, setDeviceType] = useState("desktop");
  const [loading, setLoading] = useState(false);

  const handleDeviceChange = (e) => {
    setDeviceType(e.target.value);
  };
  const handlecontactusChange = (e) => {
    const { name, value } = e.target;
    setcontactsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getaboutusbanner = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppybredcom"
      );
      var newBaner = response.data.filter((item) => item.title == "Contact");
      console.log(newBaner);
      setAbouUsBannerData(newBaner);
    } catch (error) {
      console.log(error);
    }
  };
  const handleaboutusBannerChange = async (e) => {
    setAbouUs(e.target.files[0]);
  };
  const handleaboutusUpload = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const handleaboutussubmit = new FormData();
      handleaboutussubmit.append("title", "Contact");
      handleaboutussubmit.append("image", aboutUs);

      handleaboutussubmit.append("device", deviceType);

      const response = await axios.put(
        `https://api.dremerz.net/api/mybankshoppybredcom/${
          deviceType == "desktop" ? "6" : "5"
        }/`,
        handleaboutussubmit
      );

      if (response.status === 200) {
        alert("About us section updated successfully");
        getaboutusbanner();
      } else {
        console.log("Failed to update About us section");
      }
    } catch (error) {
      console.error("Error updating about us section:", error);
    }
    setLoading(false);
  };

  const handlecontactusSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData();
      Object.keys(contactsData).forEach((key) => {
        formData.append(key, contactsData[key]);
      });

      const response = await axios.put(
        "https://api.dremerz.net/api/mybankshoppycontactuspagesection/1/",
        formData
      );

      if (response.status === 200) {
        alert("contact section updated successfully");
      } else {
        console.log("Failed to update contact section");
      }
    } catch (error) {
      console.error("Error updating servcontactice section:", error);
    }
    setLoading(false);
  };

  const getServiceData = async () => {
    try {
      const response = await axios.get(
        "https://api.dremerz.net/api/mybankshoppycontactuspagesection"
      );
      setcontactsData(response.data[0]);
      console.log(response.data, "contactdata");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getServiceData();
    getaboutusbanner();
  }, []);

  return (
    <div className="container">
      <div>
        <h2 style={{ textAlign: "center" }}>ContactUs banner</h2>
        <div className="form-group">
          <label htmlFor="deviceType" className="form-label">
            Select Device Type:
          </label>
          <select
            id="deviceType"
            className="form-control"
            value={deviceType}
            onChange={handleDeviceChange}
          >
            <option value="desktop">Desktop</option>
            <option value="mobile">Mobile</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="image">Upload Image aboutus </label>
          <input
            type="file"
            id="image"
            name="image"
            className="form-control"
            onChange={handleaboutusBannerChange}
          />
          <br></br>
          <button className="btn btn-primary" onClick={handleaboutusUpload}>
            {loading ? <Spinner /> : "Update"}
          </button>
          <br></br>
          {abouUsBannerData?.map((item) => {
            return item.device === deviceType ? (
              <img
                src={`https://api.dremerz.net/uploads/${item.image}`}
                style={{ height: "100px", width: "300px" }}
              />
            ) : (
              ""
            );
          })}
        </div>
      </div>
      <br></br>
      <h2 style={{ textAlign: "center" }}>ContactUs update</h2>
      <form onSubmit={handlecontactusSubmit}>
        {/* Input 1 */}
        {/* <div className="form-group">
          <label htmlFor="image1">Upload Image </label>
          <input
            type="file"
            id="image1"
            name="image1"
            className="form-control"
            onChange={handlecontactusChange}
          />
          <img
            src={`https://api.dremerz.net/uploads/${contactsData.input1}`}
            style={{ maxHeight: "100px", maxWidth: "200px" }}
          />
        </div> */}
        {/* Input 2 */}
        <div className="form-group">
          <label htmlFor="input3">Title</label>
          <input
            type="text"
            id="input3"
            name="input3"
            className="form-control"
            value={contactsData.input2}
            onChange={handlecontactusChange}
          />
        </div>
        {/* Input 3 */}
        <div className="form-group">
          <label htmlFor="input3">Heading</label>
          <input
            type="text"
            id="input3"
            name="input3"
            className="form-control"
            value={contactsData.input3}
            onChange={handlecontactusChange}
          />
        </div>
        {/* Input 4 */}
        <div className="form-group">
          <label htmlFor="input4">Description</label>
          <input
            type="text"
            id="input4"
            name="input4"
            className="form-control"
            value={contactsData.input4}
            onChange={handlecontactusChange}
          />
        </div>
        {/* Input 5 */}
        <div className="form-group">
          <label htmlFor="input5">Send message link</label>
          <input
            type="text"
            id="input5"
            name="input5"
            className="form-control"
            value={contactsData.input5}
            onChange={handlecontactusChange}
          />
        </div>
        {/* Input 6 */}
        <div className="form-group">
          <label htmlFor="input6">contact mobile number</label>
          <input
            type="text"
            id="input6"
            name="input6"
            className="form-control"
            value={contactsData.input6}
            onChange={handlecontactusChange}
          />
        </div>
        {/* Input 7 */}
        <div className="form-group">
          <label htmlFor="image2">contact email</label>
          <input
            type="text"
            id="image2"
            name="image2"
            className="form-control"
            value={contactsData.input7}
            onChange={handlecontactusChange}
          />
        </div>
        {/* Input 8 */}
        <div className="form-group">
          <label htmlFor="input8">contact address</label>
          <input
            type="text"
            id="input8"
            name="input8"
            className="form-control"
            value={contactsData.input8}
            onChange={handlecontactusChange}
          />
        </div>
        <br></br>

        <button type="submit" className="btn btn-primary">
          {loading ? <Spinner /> : "Update"}
        </button>
      </form>
      <hr></hr>
    </div>
  );
}

export default ContactusUploadForm;
